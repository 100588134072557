import React, { useState } from 'react';

const AddResultPage = () => {
  const initialResultData = [
    { rn: '1', name: 'Feroz', fatherName: 'Mahmood Tufail', english: 0, urdu: 0, science: 0, math: 0, islamiyat: 0, history: 0 },
    { rn: '2', name: 'Feroz', fatherName: 'Mahmood Tufail', english: 0, urdu: 0, science: 0, math: 0, islamiyat: 0, history: 0 },
    { rn: '3', name: 'Feroz', fatherName: 'Mahmood Tufail', english: 0, urdu: 0, science: 0, math: 0, islamiyat: 0, history: 0 },
    { rn: '4', name: 'Feroz', fatherName: 'Mahmood Tufail', english: 0, urdu: 0, science: 0, math: 0, islamiyat: 0, history: 0 },
  ];

  const initialResultHead = [
    { head: 'Total Marks:', english: 0, urdu: 0, science: 0, math: 0, islamiyat: 0, history: 0 },
    { head: 'Passing Marks:', english: 0, urdu: 0, science: 0, math: 0, islamiyat: 0, history: 0 },
    { head: 'Total Obtain:', english: 0, urdu: 0, science: 0, math: 0, islamiyat: 0, history: 0 },
  ];

  const [resultData, setResultData] = useState(initialResultData);
  const [resultHead, setResultHead] = useState(initialResultHead);

  const handleResultChange = (index, field, value) => {
    const updatedResults = resultData.map((result, i) =>
      i === index ? { ...result, [field]: value } : result
    );
    setResultData(updatedResults);
  };

  const handleHeadChange = (index, field, value) => {
    const updatedHeads = resultHead.map((head, i) =>
      i === index ? { ...head, [field]: value } : head
    );
    setResultHead(updatedHeads);
  };

  const inputStyle = 'w-full border-none bg-transparent text-right';

  return (
    <div className="space-y-2" style={{ fontFamily: 'Roboto, sans-serif' }}>

      <div className='bg-white p-2'>
        <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">FILTERS</h4>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-2">
          {/* Dropdown 1 */}
          <div>
            <select className="form-select block w-full p-2 border border-gray-300 bg-gray-50" id="dropdown3">
              <option value="">Select Exam Group</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </select>
          </div>

          {/* Dropdown 2 */}
          <div>
            <select className="form-select block w-full p-2 border border-gray-300 bg-gray-50" id="dropdown4">
              <option value="">Select Exam</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </select>
          </div>

          {/* Dropdown 3 */}
          <div>
            <select className="form-select block w-full p-2 border border-gray-300 bg-gray-50" id="dropdown1">
              <option value="">Select Class</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </select>
          </div>

          {/* Dropdown 4 */}
          <div>
            <select className="form-select block w-full p-2 border border-gray-300 bg-gray-50" id="dropdown2">
              <option value="">Select Section</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </select>
          </div>
          <button className="bg-blue-500 text-white px-6 py-2 hover:bg-blue-600">
          <i className="fa-solid fa-magnifying-glass"></i> Search
        </button>
        </div>
      </div>

      <div className="bg-white p-2">
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">REQUIRED CREDENTIALS</h4>
        <table className="table-auto w-full text-left bg-gray-50 ">
          <tbody>
            {resultHead.map((data, index) => (
              <tr key={index} className="border-b">
                <td className="px-4 py-2 font-semibold">{data.head}</td>
                {['english', 'urdu', 'math', 'science', 'islamiyat', 'history'].map((subject) => (
                  <td className="px-4 py-2 border-b" key={subject}>
                    <input
                      className={inputStyle}
                      type="number"
                      value={data[subject]}
                      onChange={(e) => handleHeadChange(index, subject, e.target.value)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className='bg-white p-2'>
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">ADD/UPDATE RESULTS</h4>
        <div className="overflow-x-autoshadow-md">
          <table className="min-w-full bg-white">
            <thead className="bg-themeColor1 text-white">
              <tr>
                <th className="px-4 py-2 text-left text-white">Roll No</th>
                <th className="px-4 py-2 text-white">Student Name</th>
                <th className="px-4 py-2 text-white">Father Name</th>
                <th className="px-4 py-2 text-white">ENGLISH</th>
                <th className="px-4 py-2 text-white">URDU</th>
                <th className="px-4 py-2 text-white text-right">MATH</th>
                <th className="px-4 py-2 text-white">SCIENCE</th>
                <th className="px-4 py-2 text-white">ISLAMIYAT</th>
                <th className="px-4 py-2 text-white">HISTORY</th>
              </tr>
            </thead>
            <tbody>
              {resultData.map((data, index) => (
                <tr key={index} className="">
                  <td className="px-4 py-2 text-left">{data.rn}</td>
                  <td className="px-4 py-2">{data.name}</td>
                  <td className="px-4 py-2">{data.fatherName}</td>
                  {['english', 'urdu', 'math', 'science', 'islamiyat', 'history'].map((subject) => (
                    <td key={subject} className="px-4 py-2">
                      <input
                        className={inputStyle}
                        type="number"
                        value={data[subject]}
                        onChange={(e) => handleResultChange(index, subject, e.target.value)}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='flex justify-end mb-2 mt-2 text-center'>
          <button className="bg-blue-500 text-white px-6 py-2 hover:bg-blue-600">
            <i className="fa-regular fa-floppy-disk me-1"></i> Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddResultPage