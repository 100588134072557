import React, { useState } from 'react';

const AddDateSheet = () => {
  const [dates, setDates] = useState({
    english: { date: '', day: '' },
    urdu: { date: '', day: '' },
    math: { date: '', day: '' },
    science: { date: '', day: '' },
  });

  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const handleDateChange = (subject, date) => {
    const newDate = new Date(date);
    const day = daysOfWeek[newDate.getDay()];
    setDates((prevDates) => ({
      ...prevDates,
      [subject]: { date, day },
    }));
  };

  return (
    <div className='space-y-2'>
      <div className='bg-white p-2'>
        <h4 className='text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2'>Add/Update Datesheet</h4>
        <div className='grid grid-cols-1 md:grid-cols-5 sm:grid-cols-3 gap-4'>
          {/* Dropdown 1 */}
          <div>
            <select className='form-select block w-full p-2 border border-gray-300 bg-gray-50'>
              <option value=''>Select Exam Group</option>
              <option value='1'>Option 1</option>
              <option value='2'>Option 2</option>
              <option value='3'>Option 3</option>
            </select>
          </div>

          {/* Dropdown 2 */}
          <div>
            <select className='form-select block w-full p-2 border border-gray-300 bg-gray-50'>
              <option value=''>Select Exam</option>
              <option value='1'>Option 1</option>
              <option value='2'>Option 2</option>
              <option value='3'>Option 3</option>
            </select>
          </div>

          {/* Dropdown 3 */}
          <div>
            <select className='form-select block w-full p-2 border border-gray-300 bg-gray-50'>
              <option value=''>Select Class</option>
              <option value='1'>Option 1</option>
              <option value='2'>Option 2</option>
              <option value='3'>Option 3</option>
            </select>
          </div>

          {/* Dropdown 4 */}
          <div>
            <select className='form-select block w-full p-2 border border-gray-300 bg-gray-50'>
              <option value=''>Select Section</option>
              <option value='1'>Option 1</option>
              <option value='2'>Option 2</option>
              <option value='3'>Option 3</option>
            </select>
          </div>
          <button className="bg-blue-500 text-white py-2 hover:bg-blue-600">
          <i className="fa-solid fa-magnifying-glass"></i> Search
        </button>
        </div>
      </div>
      <div className='bg-white p-2'>
        <div className="bg-white">
          <table className="min-w-full divide-y divide-gray-200 shadow-md">
            <thead className="bg-themeColor1">
              <tr>
                <th scope="col" className="text-left text-white py-2 px-4">Subjects</th>
                <th scope="col" className="text-center text-white py-2 px-4">Exam Date</th>
                <th scope="col" className="text-center text-white py-2 px-4">Day</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {Object.entries(dates).map(([subject, { date, day }]) => (
                <tr key={subject}>
                  <td className="py-2 px-4 text-left">{subject.charAt(0).toUpperCase() + subject.slice(1)}</td>
                  <td className="py-2 px-4">
                    <input
                      type="date"
                      className="form-control text-black border border-gray-300 bg-gray-50 w-full p-2"
                      value={date}
                      onChange={(e) => handleDateChange(subject, e.target.value)}
                    />
                  </td>
                  <td className="py-2 px-4">
                    <input
                      type="text"
                      className="form-control text-black border border-gray-300 bg-gray-50 w-full p-2"
                      value={day}
                      readOnly
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Save Button */}
        <div className='flex justify-end mb-2 mt-2 text-center'>
          <button className="bg-blue-500 text-white px-6 py-2 hover:bg-blue-600">
            <i className="fa-regular fa-floppy-disk me-1"></i> Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddDateSheet;
