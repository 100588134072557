import React, { useEffect, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

function StudentList() {
  const [classes, setClasses] = useState([]);
  const [yearSession, setYearSession] = useState([]);
  const [sections, setSections] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [genders, setGenders] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedMedium, setSelectedMedium] = useState("");
  const [selectedSession, setSelectedSession] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedYearSession, setSelectedYearSession] = useState("");
  const userRoleData = JSON.parse(localStorage.getItem("employeeRoleData"));

  const handleToggle = (studentId) => {
    const updatedStudents = students.map((student) => {
      if (student.studentId === studentId) {
        return { ...student, isActive: !student.isActive };
      }
      return student;
    });
    setStudents(updatedStudents);
  };
  useEffect(() => {
    fetch("https://dotnet.rapidmis.com/studentlookup", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setClasses(data.classesWithSessionList);
        setMediums(data.mediumList);
        setGenders(data.genderList);
        setStatuses(data.statusList);
        setYearSession(data.yearSessionList);

        const activeYearSession = data.yearSessionList.find(
          (session) => session.isActive
        );

        if (activeYearSession) {
          const yearSessionId = activeYearSession.yearSessionId;
          setSelectedYearSession(yearSessionId);
          const url = `https://dotnet.rapidmis.com/studentgetall?yearSessionId=${yearSessionId}`;
          fetch(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to fetch student data");
              }
              return response.json();
            })
            .then((studentData) => {
              setStudents(studentData);
              setFilteredStudents(studentData);
            })
            .catch((error) =>
              console.error("Error fetching student data:", error)
            );
        } else {
          console.error("No active year session found.");
        }
      })
      .catch((error) => console.error("Error fetching lookup data:", error));
  }, []);

  const handleSearch = () => {
    if (!selectedYearSession) {
      toast.error("Please select a Year Session.");
      return;
    }
    const queryParams = new URLSearchParams();

    if (selectedClass) queryParams.append("ClassId", selectedClass);
    if (selectedSection) queryParams.append("SectionId", selectedSection);
    if (searchQuery) {
      queryParams.append("RegNo", searchQuery);
      queryParams.append("FamilyId", searchQuery);
    }
    if (selectedMedium) queryParams.append("MediumId", selectedMedium);
    if (selectedSession) queryParams.append("SessionId", selectedSession);
    if (selectedStatus)
      queryParams.append("IsActive", selectedStatus === "Active");
    if (selectedGender) queryParams.append("GenderId", selectedGender);
    if (selectedYearSession)
      queryParams.append("YearSessionId", selectedYearSession);

    const url = `https://dotnet.rapidmis.com/studentgetall?${queryParams.toString()}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setFilteredStudents(data);
      })
      .catch((error) =>
        console.error("Error fetching filtered student data:", error)
      );
  };

  const resetFilters = () => {
    setSelectedClass("");
    setSelectedSection("");
    setSelectedMedium("");
    setSelectedSession("");
    setSelectedStatus("");
    setSelectedGender("");
    setSelectedYearSession("");
    setSearchQuery("");
    setFilteredStudents(students);
  };
  const handleClassChange = (e) => {
    const classId = e.target.value;
    setSelectedClass(classId);

    const selectedClassData = classes.find(
      (cls) => cls.classId === parseInt(classId)
    );
    if (selectedClassData) {
      setSections(selectedClassData.sectionList || []);
    } else {
      setSections([]);
    }
    setSelectedSection("");
  };

  const isActionAvailable = (moduleId, featureId, actionId) => {
    if (
      !userRoleData ||
      !userRoleData.rolePermission ||
      !userRoleData.rolePermission.moduleList
    )
      return false;

    return userRoleData.rolePermission.moduleList.some(
      (module) =>
        module.moduleId === moduleId &&
        module.featureList.some(
          (feature) =>
            feature.featureId === featureId &&
            feature.actionList.some((action) => action.actionId === actionId)
        )
    );
  };

  return (
    <div className="space-y-4" style={{ fontFamily: "Roboto, sans-serif" }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isActionAvailable(3, 301, 30101) && (
        <div className="bg-white p-2">
          <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1">
            FILTERS
          </h4>
          {/* Filters and Inputs */}
          <div className="flex flex-col md:flex-row md:space-x-2 my-2">
            {/* Year Session Dropdown */}
            <div className="flex-grow md:w-1/4">
              <div className="relative">
                <MenuOutlined className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
                <select
                  className="block w-full border border-gray-300 py-2 pl-10 pr-3 bg-gray-50"
                  value={selectedYearSession}
                  required
                  onChange={(e) => setSelectedYearSession(e.target.value)}
                >
                  <option value="">Select Year Session</option>
                  {yearSession.map((session) => (
                    <option
                      key={session.yearSessionId}
                      value={session.yearSessionId.toString()}
                    >
                      {session.yearSessionName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Dropdown 1 - Class */}
            <div className="flex-grow md:w-1/4">
              <div className="relative">
                <MenuOutlined className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
                <select
                  className="block w-full border border-gray-300 py-2 pl-10 pr-3 bg-gray-50"
                  value={selectedClass}
                  onChange={handleClassChange}
                >
                  <option value="">Select Class</option>
                  {classes.map((cls) => (
                    <option key={cls.classId} value={cls.classId}>
                      {cls.classDisplayName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Dropdown 2 - Section */}
            <div className="flex-grow md:w-1/4">
              <div className="relative">
                <MenuOutlined className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
                <select
                  className="block w-full border border-gray-300 py-2 pl-10 pr-3 bg-gray-50"
                  value={selectedSection}
                  onChange={(e) => setSelectedSection(e.target.value)}
                  disabled={sections.length === 0}
                >
                  <option value="">Select Section</option>
                  {sections.map((section) => (
                    <option key={section.sectionId} value={section.sectionId}>
                      {section.sectionDisplayName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Dropdown 3 - Medium */}
            <div className="flex-grow md:w-1/4">
              <div className="relative">
                <MenuOutlined className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
                <select
                  className="block w-full border border-gray-300 py-2 pl-10 pr-3 bg-gray-50"
                  value={selectedMedium}
                  onChange={(e) => setSelectedMedium(e.target.value)}
                >
                  <option value="">Select Medium</option>
                  {mediums.map((medium) => (
                    <option key={medium.mediumId} value={medium.mediumId}>
                      {medium.displayName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Dropdown 4 - Gender */}
            <div className="flex-grow md:w-1/4">
              <div className="relative">
                <MenuOutlined className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
                <select
                  className="block w-full border border-gray-300 py-2 pl-10 pr-3 bg-gray-50"
                  value={selectedGender}
                  onChange={(e) => setSelectedGender(e.target.value)}
                >
                  <option value="">Select Gender</option>
                  {genders.map((gender) => (
                    <option key={gender.genderId} value={gender.genderId}>
                      {gender.genderName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Dropdown 5 - Status */}
            <div className="flex-grow md:w-1/4">
              <div className="relative">
                <MenuOutlined className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
                <select
                  className="block w-full border border-gray-300 py-2 pl-10 pr-3 bg-gray-50"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  <option value="">Select Status</option>
                  {statuses.map((status) => (
                    <option
                      key={status.statusId}
                      value={status.statusId.toString()}
                    >
                      {status.statusName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2 my-2">
            <form class="flex-grow w-full">
              <label
                for="default-search"
                class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div class="relative">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    class="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search..."
                  required
                />
              </div>
            </form>
            <div className="flex gap-2 w-1/5">
              <button
                className="bg-blue-500 w-full text-white py-2 hover:bg-blue-600"
                onClick={resetFilters}
              >
                <i class="fa-solid fa-arrows-rotate"></i> Clear
              </button>
              <button
                className="bg-blue-500 w-full text-white py-2 hover:bg-blue-600"
                onClick={handleSearch}
              >
                <i class="fa-solid fa-filter"></i> Filter
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white p-2">
        <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">
          Student List
        </h4>
        {isActionAvailable(3, 301, 30102) && (
          <div className="flex justify-end mb-2 mt-2">
            <Link to="/panel/addStudent">
              <button className="bg-blue-500 w-full text-white  px-6 py-2 hover:bg-blue-600">
                <i className="fa-solid fa-plus"></i> Add New
              </button>
            </Link>
          </div>
        )}
        {isActionAvailable(3, 301, 30101) && (
          <div className="overflow-x-auto shadow-md">
            <table className="min-w-full">
              <thead>
                <tr className="bg-themeColor1">
                  <th className="border-b border-gray-300 px-2 py-3 text-white">
                    Family Id
                  </th>
                  <th className="border-b border-gray-300 px-2 py-3 text-white">
                    Reg No
                  </th>
                  <th className="border-b border-gray-300 px-2 py-3 text-white">
                    Student Name
                  </th>
                  <th className="border-b border-gray-300 px-2 py-3 text-white">
                    Father Name
                  </th>
                  <th className="border-b border-gray-300 px-2 py-3 text-white">
                    Class
                  </th>
                  <th className="border-b border-gray-300 px-2 py-3 text-white">
                    Section
                  </th>
                  <th className="border-b border-gray-300 px-2 py-3 text-white">
                    Gender
                  </th>
                  <th className="border-b border-gray-300 px-2 py-3 text-white">
                    Status
                  </th>
                  {isActionAvailable(3, 301, 30103) && (
                    <th className="border-b border-gray-300 px-2 py-3 text-white">
                      Actions
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredStudents.length > 0 ? (
                  filteredStudents.map((student) => (
                    <tr key={student.studentId}>
                      <td className="border-b border-gray-300 px-2 py-3">
                        {student.familyId || "N/A"}
                      </td>
                      <td className="border-b border-gray-300 px-2 py-3">
                        {student.regNo || "N/A"}
                      </td>
                      <td className="border-b border-gray-300 px-2 py-3">
                        {student.studentName || "N/A"}
                      </td>
                      <td className="border-b border-gray-300 px-2 py-3">
                        {student.fatherName || "N/A"}
                      </td>
                      <td className="border-b border-gray-300 px-2 py-3">
                        {student.classDisplayName || "N/A"}
                      </td>
                      <td className="border-b border-gray-300 px-2 py-3">
                        {student.sectionDisplayName || "N/A"}
                      </td>
                      <td className="border-b border-gray-300 px-2 py-3">
                        {student.gender || "N/A"}
                      </td>
                      <td className="border-b border-gray-300 px-2 py-3">
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={student.isActive}
                            onChange={() => handleToggle(student.studentId)}
                          />
                          <div className="w-9 h-5 bg-gray-300 rounded-full peer-checked:bg-blue-600 peer-focus:ring-4 peer-focus:ring-blue-300 transition-all duration-300"></div>
                          <div className="absolute left-[2px] top-[2px] w-4 h-4 bg-white rounded-full border border-gray-300 peer-checked:translate-x-full peer-checked:border-green-500 transition-all duration-300"></div>
                        </label>
                      </td>
                      {isActionAvailable(3, 301, 30103) && (
                        <td className="py-2 px-4 border-b border-gray-300">
                          <Link
                            to={`/panel/updateStudent/${encodeURIComponent(
                              student.studentId
                            )}`}
                            className=" hover:underline hover:text-themeColor2"
                          >
                            <i className="fa-regular fa-pen-to-square"></i>
                          </Link>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="9"
                      className="border-b border-gray-300 px-2 py-3 text-center"
                    >
                      <Empty />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default StudentList;
