import React, { useState, useEffect } from "react";
import axios from "axios";
import { Empty } from "antd";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddCampus() {
  const [campusData, setCampusData] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [newCampus, setNewCampus] = useState({ tenantName: "", address: "" });
  const [editingCampus, setEditingCampus] = useState(null);
  const userRoleData = JSON.parse(localStorage.getItem("employeeRoleData"));

  useEffect(() => {
    fetchCampuses();
  }, []);

  const fetchCampuses = async () => {
    try {
      const response = await axios.get(
        "https://dotnet.rapidmis.com/tenantgetall",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setCampusData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSaveNewCampus = async () => {
    if (newCampus.tenantName.trim() === "" || newCampus.address.trim() === "") {
      toast.error("Please enter both Campus Name and Address");
      return;
    }

    try {
      const response = await axios.post(
        "https://dotnet.rapidmis.com/tenantcreate",
        newCampus,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setCampusData((prevData) => [...prevData, response.data]);
      setCreateModalOpen(false);
      setNewCampus({ tenantName: "", address: "" });
      toast.success("Campus added successfully!");
      fetchCampuses();
    } catch (error) {
      console.error("Error adding new campus:", error);
      toast.error("Error adding campus.");
    }
  };

  const handleEdit = (campusItem) => {
    setEditingCampus(campusItem);
    setUpdateModalOpen(true);
  };

  const handleUpdateCampus = async (id) => {
    const updatedCampusData = {
      tenantId: editingCampus.tenantId,
      tenantName: editingCampus.tenantName,
      address: editingCampus.address,
      isActive: editingCampus.isActive,
    };

    try {
      const response = await axios.put(
        `https://dotnet.rapidmis.com/tenantupdate/${editingCampus.tenantId}`,
        updatedCampusData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      const updatedCampusList = campusData.map((item) =>
        item.campusId === editingCampus.campusId ? response.data : item
      );

      setCampusData(updatedCampusList);
      setUpdateModalOpen(false);
      setEditingCampus(null);
      toast.success("Campus updated successfully!");
      fetchCampuses();
    } catch (error) {
      console.error("Error updating campus:", error);
      toast.error("Error updating campus.");
    }
  };

  const handleCancelCreate = () => {
    setCreateModalOpen(false);
    setNewCampus({ tenantName: "", address: "" });
  };

  const handleCancelUpdate = () => {
    setUpdateModalOpen(false);
    setEditingCampus(null);
  };

  const handleCreateInputChange = (e) => {
    const { name, value } = e.target;
    setNewCampus({ ...newCampus, [name]: value });
  };

  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setEditingCampus({ ...editingCampus, [name]: value });
  };

  const handleStatusToggleChange = () => {
    setEditingCampus({ ...editingCampus, isActive: !editingCampus.isActive });
  };

  const addNewCampus = () => {
    setCreateModalOpen(true);
  };
  const isActionAvailable = (moduleId, featureId, actionId) => {
    if (
      !userRoleData ||
      !userRoleData.rolePermission ||
      !userRoleData.rolePermission.moduleList
    )
      return false;

    return userRoleData.rolePermission.moduleList.some(
      (module) =>
        module.moduleId === moduleId &&
        module.featureList.some(
          (feature) =>
            feature.featureId === featureId &&
            feature.actionList.some((action) => action.actionId === actionId)
        )
    );
  };
  return (
    <div className="bg-white p-2" style={{ fontFamily: "Roboto, sans-serif" }}>
      <ToastContainer />
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1">
        CAMPUS LIST
      </h4>

      {isActionAvailable(1, 105, 10502) && (
        <div className="flex justify-end mb-2 mt-2">
          <button
            className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-600"
            onClick={addNewCampus}
          >
            <i className="fa-solid fa-plus"></i> Add New
          </button>
        </div>
      )}
      {isActionAvailable(1, 105, 10501) && (
        <div
          className="mt-4 overflow-y-auto shadow-md"
          style={{ maxHeight: "500px" }}
        >
          <table className="min-w-full bg-white">
            <thead className="bg-themeColor1 sticky top-0 z-10">
              <tr>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  Sr
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  Campus Name
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  Address
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  Status
                </th>
                {isActionAvailable(1, 105, 10503) && (
                  <th className="py-2 px-4 border-b border-gray-300 text-white">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {campusData.length === 0 ? (
                <tr>
                  <td colSpan="5">
                    <div className="flex flex-col items-center justify-center my-10">
                      <Empty />
                    </div>
                  </td>
                </tr>
              ) : (
                campusData.map((campusItem, index) => (
                  <tr key={campusItem.campusId} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b border-gray-300">
                      {index + 1}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {campusItem.tenantName}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {campusItem.address}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {/* Status Toggle */}
                      <label className="inline-flex items-center cursor-default">
                        <input
                          type="checkbox"
                          checked={campusItem.isActive}
                          readOnly
                          className="sr-only peer"
                        />
                        <div
                          className={`relative w-9 h-5 ${
                            campusItem.isActive ? "bg-blue-600" : "bg-gray-200"
                          } rounded-full`}
                        >
                          <div
                            className={`absolute top-[2px] left-[2px] bg-white border rounded-full w-4 h-4 transition-all ${
                              campusItem.isActive ? "translate-x-full" : ""
                            }`}
                          ></div>
                        </div>
                      </label>
                    </td>
                    {isActionAvailable(1, 105, 10503) && (
                      <td className="py-2 px-4 border-b border-gray-300">
                        <i
                          className="fa-regular fa-pen-to-square cursor-pointer"
                          onClick={() => handleEdit(campusItem)}
                        ></i>
                      </td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
      {/* Modal for Creating Campus */}
      {createModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-lg z-60">
            <h4 className="text-lg font-bold mb-4 ps-2 mt-2 border-b-2 border-themeColor text-themeColor text-left">
              Add Campus
            </h4>
            <div className="px-4">
              <div className="relative mb-4">
                <input
                  type="text"
                  name="tenantName"
                  value={newCampus.tenantName}
                  onChange={handleCreateInputChange}
                  className="block w-full px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                  placeholder=" "
                  required
                />
                <label className="absolute text-sm text-themeColor duration-300 transform -translate-y-4 scale-75 top-2 left-2 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-themeColor">
                  Campus Name
                </label>
              </div>
              <div className="relative mb-4">
                <input
                  type="text"
                  name="address"
                  value={newCampus.address}
                  onChange={handleCreateInputChange}
                  className="block w-full px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                  placeholder=" "
                  required
                />
                <label className="absolute text-sm text-themeColor duration-300 transform -translate-y-4 scale-75 top-2 left-2 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-themeColor">
                  Campus Address
                </label>
              </div>
            </div>
            <div className="flex justify-end p-4">
              <button
                className="bg-gray-400 text-white py-2 px-4 rounded-lg mr-2 hover:bg-gray-500"
                onClick={handleCancelCreate}
              >
                Cancel
              </button>
              <button
                className="bg-themeColor text-white py-2 px-4 rounded-lg hover:bg-lightThemeColor"
                onClick={handleSaveNewCampus}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Updating Campus */}
      {updateModalOpen && editingCampus && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-lg z-60">
            <h4 className="text-lg font-bold mb-4 ps-2 mt-2 border-b-2 border-themeColor text-themeColor text-left">
              Update Campus
            </h4>
            <div className="px-4">
              <div className="relative mb-4">
                <input
                  type="text"
                  name="tenantName"
                  value={editingCampus.tenantName}
                  onChange={handleUpdateInputChange}
                  className="block w-full px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                  placeholder=" "
                  required
                />
                <label className="absolute text-sm text-themeColor duration-300 transform -translate-y-4 scale-75 top-2 left-2 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-themeColor">
                  Campus Name
                </label>
              </div>
              <div className="relative mb-4">
                <input
                  type="text"
                  name="address"
                  value={editingCampus.address}
                  onChange={handleUpdateInputChange}
                  className="block w-full px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                  placeholder=" "
                  required
                />
                <label className="absolute text-sm text-themeColor duration-300 transform -translate-y-4 scale-75 top-2 left-2 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-themeColor">
                  Campus Address
                </label>
              </div>

              {/* Status Toggle */}
              <div className="relative mb-4 flex gap-2">
                <label className="text-sm text-themeColor font-semibold block">
                  Status
                </label>
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={editingCampus.isActive}
                    onChange={handleStatusToggleChange}
                    className="sr-only peer"
                  />
                  <div
                    className={`relative w-9 h-5 ${
                      editingCampus.isActive ? "bg-blue-600" : "bg-gray-200"
                    } rounded-full`}
                  >
                    <div
                      className={`absolute top-[2px] left-[2px] bg-white border rounded-full w-4 h-4 transition-all ${
                        editingCampus.isActive ? "translate-x-full" : ""
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
            </div>
            <div className="flex justify-end p-4">
              <button
                className="bg-gray-400 text-white py-2 px-4 rounded-lg mr-2 hover:bg-gray-500"
                onClick={handleCancelUpdate}
              >
                Cancel
              </button>
              <button
                className="bg-themeColor text-white py-2 px-4 rounded-lg hover:bg-lightThemeColor"
                onClick={handleUpdateCampus}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddCampus;
