import React from 'react';
import LoginComponent from './LoginComponent';

const LoginScreen = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 px-6">
      <div className="w-full flex flex-col lg:flex-row items-center">
        {/* Left Section: Image */}
        <div className="w-full lg:w-1/2 text-center mb-4 lg:mb-0">
          <img 
            src="../images/MISLaunch1.jpg" 
            alt="Launch"
            className="w-[45rem] h-auto ms-auto"
            style={{mixBlendMode: 'multiply'}}
          />
        </div>

        {/* Right Section: Login Form */}
        <div className="w-full lg:w-1/2 flex flex-col items-center">
          <LoginComponent />
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
