import React, { useState } from "react";

const FeeReceiving = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  return (
    <div className="relative" style={{ fontFamily: 'Roboto, sans-serif' }}>
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row gap-2">
          {/* Left Section: Search and Fee Details */}
          <div className="w-full md:w-1/2 bg-white">
            {/* Search Bar */}
            <div className="m-2">
              <div className="relative">
                <input
                  type="text"
                  className="form-input w-full bg-gray-50 border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Search Student Family ID"
                />
                <button
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-blue-600 text-white px-4 py-2 hover:bg-blue-700"
                  type="button"
                >
                  <i className="fas fa-search"></i> Search
                </button>
              </div>
            </div>
            {/* Student Details */}
            <div className="bg-gray-50 border border-gray-300 mx-2 p-2">
              <div className="flex justify-between">
                <p className="font-bold text-gray-700">
                  Family Id: <span className="font-normal">375</span>
                </p>
                <p className="font-bold text-gray-700">
                  Contact: <span className="font-normal">0300-0000000</span>
                </p>
              </div>
              <div className="flex justify-between">
                <p className="font-bold text-gray-700">
                  Father Name: <span className="font-normal">Mahmood Tufail</span>
                </p>
                <p className="font-bold text-gray-700">
                  WhatsApp: <span className="font-normal">0300-0000000</span>
                </p>
              </div>
              <div className="flex justify-between">
                <p className="font-bold text-gray-700">
                  CNIC: <span className="font-normal">34135-8796744-8</span>
                </p>
                <p className="font-bold text-gray-700">
                  Address: <span className="font-normal">xyz</span>
                </p>
              </div>
            </div>

            {/* Voucher Table */}
            <div className="m-2">
              <h3 className="text-lg font-semibold bg-themeColor1 text-white py-1">Voucher Details</h3>
              <div className="overflow-auto">
                <table className="w-full text-left">
                <tbody>
                    <tr className="border-b border-gray-300 hover:bg-gray-50">
                      <th className="text-left font-semibold text-gray-700 py-2 ps-2">Tuition Fee:</th>
                      <td className="text-gray-600 text-right pe-2">154</td>
                    </tr>
                    <tr className="border-b border-gray-300 hover:bg-gray-50">
                      <th className="text-left font-semibold text-gray-700 py-2 ps-2">Discount:</th>
                      <td className="text-gray-600 text-right pe-2">154</td>
                    </tr>
                    <tr className="border-b border-gray-300 hover:bg-gray-50">
                      <th className="text-left font-semibold text-gray-700 py-2 ps-2">Arrears:</th>
                      <td className="text-gray-600 text-right pe-2">154</td>
                    </tr>
                    <tr className="border-b border-gray-300 hover:bg-gray-50">
                      <th className="text-left font-semibold text-gray-700 py-2 ps-2">Stationary:</th>
                      <td className="text-gray-600 text-right pe-2">154</td>
                    </tr>
                    <tr className="border-b border-gray-300 hover:bg-gray-50">
                      <th className="text-left font-semibold text-gray-700 py-2 ps-2">Conveyance:</th>
                      <td className="text-gray-600 text-right pe-2">154</td>
                    </tr>
                    <tr className="border-b border-gray-300 hover:bg-gray-50">
                      <th className="text-left font-semibold text-gray-700 py-2 ps-2">Total Dues:</th>
                      <td className="text-gray-600 text-right pe-2">154</td>
                    </tr>
                    <tr className="border-b border-gray-300 hover:bg-gray-50">
                      <th className="text-left font-semibold text-gray-700 py-2 ps-2">Late Fee Fine:</th>
                      <td className="text-gray-600 text-right pe-2">154</td>
                    </tr>
                    <tr className="border-b border-gray-300 hover:bg-gray-50">
                      <th className="text-left font-semibold text-gray-700 py-2 ps-2">After Due Date:</th>
                      <td className="text-gray-600 text-right pe-2">154</td>
                    </tr>
                    <tr className="border-b border-gray-300 hover:bg-gray-50">
                      <th className="text-left font-semibold text-gray-700 py-2 ps-2">Due Date:</th>
                      <td className="text-gray-600 text-right pe-2">154</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Right Section: Fee History */}
          <div className="w-full md:w-1/2 bg-white">
            <div className=" overflow-y-auto m-2">
              <h3 className="text-lg font-semibold bg-themeColor1 text-white py-1 mb-2">
                History
              </h3>
              <table className="w-full text-left bg-white overflow-hidden">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="p-2">Month</th>
                    <th className="p-2">Total Bill</th>
                    <th className="p-2">Total Receive</th>
                    <th className="p-2">Balance</th>
                    <th className="p-2">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Sample history rows */}
                  <tr className="border-b hover:bg-gray-50">
                    <td className="text-gray-600 p-2">June</td>
                    <td className="text-gray-600 p-2">3450</td>
                    <td className="text-gray-600 p-2">3000</td>
                    <td className="text-gray-600 p-2">450</td>
                    <td className="text-gray-600 p-2">6/14/2024</td>
                  </tr>
                  <tr className="border-b hover:bg-gray-50">
                    <td className="text-gray-600 p-2">May</td>
                    <td className="text-gray-600 p-2">3450</td>
                    <td className="text-gray-600 p-2">3000</td>
                    <td className="text-gray-600 p-2">450</td>
                    <td className="text-gray-600 p-2">6/14/2024</td>
                  </tr>
                  <tr className="border-b hover:bg-gray-50">
                    <td className="text-gray-600 p-2">June</td>
                    <td className="text-gray-600 p-2">3450</td>
                    <td className="text-gray-600 p-2">3000</td>
                    <td className="text-gray-600 p-2">450</td>
                    <td className="text-gray-600 p-2">6/14/2024</td>
                  </tr>
                  <tr className="border-b hover:bg-gray-50">
                    <td className="text-gray-600 p-2">May</td>
                    <td className="text-gray-600 p-2">3450</td>
                    <td className="text-gray-600 p-2">3000</td>
                    <td className="text-gray-600 p-2">450</td>
                    <td className="text-gray-600 p-2">6/14/2024</td>
                  </tr>
                  <tr className="border-b hover:bg-gray-50">
                    <td className="text-gray-600 p-2">June</td>
                    <td className="text-gray-600 p-2">3450</td>
                    <td className="text-gray-600 p-2">3000</td>
                    <td className="text-gray-600 p-2">450</td>
                    <td className="text-gray-600 p-2">6/14/2024</td>
                  </tr>
                  <tr className="border-b hover:bg-gray-50">
                    <td className="text-gray-600 p-2">May</td>
                    <td className="text-gray-600 p-2">3450</td>
                    <td className="text-gray-600 p-2">3000</td>
                    <td className="text-gray-600 p-2">450</td>
                    <td className="text-gray-600 p-2">6/14/2024</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Floating Button */}
      <button
        className="fixed bottom-6 right-6 bg-blue-600 text-white font-semibold py-2 px-4 hover:bg-blue-700 transition-all duration-300"
        onClick={showModal}
      >
        RECEIVING
      </button>

      {isModalVisible && (
  <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg shadow-xl w-11/12 max-w-lg z-60 transform transition-all duration-300 ease-in-out">
      <h4 className="text-lg font-bold mb-4 ps-2 mt-2  border-b-2 border-themeColor text-left text-themeColor">
        Fee Receiving
      </h4>
      <div className="px-4 py-2 space-y-4">
        {/* Month From */}
        <div className="flex items-center">
          <label className="w-1/4 text-gray-700 font-semibold text-left">Month From</label>
          <select className="w-3/4 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500">
            <option value="">Select Month</option>
            {months.map((month, index) => (
              <option key={index} value={month}>{month}</option>
            ))}
          </select>
        </div>

        {/* Month To */}
        <div className="flex items-center">
          <label className="w-1/4 text-gray-700 font-semibold text-left">Month To</label>
          <select className="w-3/4 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500">
            <option value="">Select Month</option>
            {months.map((month, index) => (
              <option key={index} value={month}>{month}</option>
            ))}
          </select>
        </div>

        {/* Date Received */}
        <div className="flex items-center">
          <label className="w-1/4 text-gray-700 font-semibold text-left">Date Received</label>
          <input type="date" className="w-3/4 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500" />
        </div>

        {/* Adjustment */}
        <div className="flex items-center">
          <label className="w-1/4 text-gray-700 font-semibold text-left">Adjustment</label>
          <input type="text" className="w-3/4 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500" placeholder="Enter adjustment amount" />
        </div>

        {/* Total Payment */}
        <div className="flex items-center">
          <label className="w-1/4 text-gray-700 font-semibold text-left">Total Payment</label>
          <input type="text" className="w-3/4 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500" placeholder="Enter total payment" />
        </div>
      </div>

      {/* Modal Footer */}
      <div className="flex justify-end p-4 space-x-2 bg-gray-100 mt-2" style={{borderBottomLeftRadius: '10px', borderBottomRightRadius:'10px'}}>
        <button onClick={handleClose} className="bg-gray-400 text-white py-2 px-4 rounded-lg hover:bg-gray-500 transition-all duration-300">
          Cancel
        </button>
        <button className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-all duration-300">
          Save
        </button>
      </div>
    </div>
  </div>
)}

     
    </div>
  );
};

export default FeeReceiving;
