import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginComponent = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // New state to control password visibility
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://dotnet.rapidmis.com/authenticationLogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userName,
          password,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        const { accessToken, instanceTypeId, instanceId } = data;
        
        localStorage.setItem('authToken', accessToken);
        console.log(`authToken: ${accessToken}`);
        localStorage.setItem('instanceTypeId', instanceTypeId);
        localStorage.setItem('instanceId', instanceId);
        
        toast.success('Login successful!');
  
        // Fetch employee role after successful login
        await fetchEmployeeRole(instanceId, accessToken);
  
        if (instanceTypeId === 1) {
          setTimeout(() => {
            navigate('/parentPanel');
          }, 1000);
        } else {
          setTimeout(() => {
            navigate('/panel');
          }, 1000);
        }
      } else {
        toast.error(data.message || 'Login failed', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('An error occurred during login.');
    }
  };
  
  const fetchEmployeeRole = async (instanceId, authToken) => {
    try {
      const response = await axios.get(`https://dotnet.rapidmis.com/employeewithpermissionbyid/${instanceId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        }
      });
  
      if (response.data) {
        // Save the employee role data to local storage
        localStorage.setItem('employeeRoleData', JSON.stringify(response.data));
        console.log('Employee role data saved:', response.data);
      }
    } catch (error) {
      console.error('Error fetching staff data:', error);
    }
  };
  
  return (
    <div className="relative z-10 bg-white p-6 shadow-lg w-full max-w-md">
      <h1 className="text-center mb-4">
        <i className="fa-solid fa-house-user text-themeColor text-4xl"></i>
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="relative mb-4">
          <input
            type="text"
            id="floatingInput"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            className="block w-full px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
            placeholder=" "
            required
          />
          <label
            htmlFor="floatingInput"
            className="absolute text-sm text-themeColor duration-300 transform -translate-y-4 scale-75 top-2 left-2 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-themeColor"
          >
            Login Id
          </label>
        </div>

        <div className="relative mb-4">
          <input
            type={showPassword ? 'text' : 'password'} // Conditionally set input type
            id="floatingPassword"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="block w-full px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
            placeholder=" "
            required
          />
          <label
            htmlFor="floatingPassword"
            className="absolute text-sm text-themeColor duration-300 transform -translate-y-4 scale-75 top-2 left-2 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-themeColor"
          >
            Password
          </label>
          <i
            className={`absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer ${showPassword ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'}`} // Toggle eye icon
            onClick={togglePasswordVisibility}
          ></i>
        </div>

        <button className="bg-themeColor text-white w-full py-2 mt-2 hover:bg-blue-800" type="submit">
          Login
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default LoginComponent;
