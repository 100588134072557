export const staffResult = [
    { 
      id: 1,
      name: "Uzma Irfan", 
      result: 63, 
      subjects: [
        { title: 'Mathematics', class: 'Class 1', score: 65 }, 
        { title: 'Science', class: 'Class 2', score: 60 },
        { title: 'Biology', class: 'Class 8', score: 22 }
      ] 
    },
    { 
      id: 2, 
      name: "Ammara Liaqat", 
      result: 34, 
      subjects: [
        { title: 'English', class: 'Class 3', score: 30 }, 
        { title: 'History', class: 'Class 4', score: 38 }, 
        { title: 'Mathematics', class: 'Class 2', score: 18 },
      ] 
    },
    { 
      id: 3, 
      name: "Muhammad Usman", 
      result: 83, 
      subjects: [
        { title: 'Physics', class: 'Class 5', score: 85 }, 
        { title: 'Chemistry', class: 'Class 6', score: 80 }, 
        { title: 'Mathematics', class: 'Class 2', score: 18 },
      ] 
    },
    { 
      id: 4, 
      name: "Muhammad Zubair", 
      result: 93, 
      subjects: [
        { title: 'Biology', class: 'Class 7', score: 95 }, 
        { title: 'Geography', class: 'Class 8', score: 90 }, 
        { title: 'Mathematics', class: 'Class 8', score: 18 },
      ] 
    },
    { 
      id: 5, 
      name: "Muhammad Iqbal", 
      result: 43, 
      subjects: [
        { title: 'Economics', class: 'Class 9', score: 40 }, 
        { title: 'Statistics', class: 'Class 10', score: 45 }
      ] 
    },
    { 
      id: 6, 
      name: "Hifza Kausar", 
      result: 63, 
      subjects: [
        { title: 'Geography', class: 'Class 1', score: 60 },  
        { title: 'Mathematics', class: 'Class 8', score: 18 },
        { title: 'Civics', class: 'Class 9', score: 65 }
      ] 
    },
    { 
      id: 7, 
      name: "Zainab Bashir", 
      result: 53, 
      subjects: [
        { title: 'Biology', class: 'Class 3', score: 55 }, 
        { title: 'Mathematics', class: 'Class 4', score: 50 }
      ] 
    },
    { 
      id: 8, 
      name: "Aqsa Jamil", 
      result: 90, 
      subjects: [
        { title: 'English', class: 'Class 1', score: 92 },
        { title: 'Mathematics', class: 'Class 2', score: 18 },
        { title: 'History', class: 'Class 6', score: 88 }
      ] 
    },
    { 
      id: 9, 
      name: "Qura tul Ain", 
      result: 50, 
      subjects: [
        { title: 'Physics', class: 'Class 8', score: 52 }, 
        { title: 'Chemistry', class: 'Class 8', score: 48 }
      ] 
    },
    { 
      id: 10, 
      name: "Jazba Rani", 
      result: 40, 
      subjects: [
        { title: 'Biology', class: 'Class 9', score: 42 }, 
        { title: 'Geography', class: 'Class 2', score: 38 }
      ] 
    },
    { 
      id: 11, 
      name: "Saira Arshad", 
      result: 78, 
      subjects: [
        { title: 'Mathematics', class: 'Class 9', score: 80 }, 
        { title: 'Science', class: 'Class 7', score: 76 }
      ] 
    },
    { 
      id: 12, 
      name: "Nimra Mushtaq", 
      result: 84, 
      subjects: [
        { title: 'English', class: 'Class 3', score: 85 }, 
        { title: 'History', class: 'Class 4', score: 83 }
      ] 
    },
    { 
      id: 13, 
      name: "Aiman Javed", 
      result: 99, 
      subjects: [
        { title: 'Physics', class: 'Class 5', score: 100 }, 
        { title: 'Chemistry', class: 'Class 6', score: 98 }
      ] 
    },
    { 
      id: 14, 
      name: "Hafsa Ilyas", 
      result: 20, 
      subjects: [
        { title: 'Biology', class: 'Class 10', score: 22 }, 
        { title: 'Mathematics', class: 'Class 8', score: 18 }
      ] 
    },
    { 
      id: 15, 
      name: "Sajida Jabeen", 
      result: 45, 
      subjects: [
        { title: 'English', class: 'Class 9', score: 47 }, 
        { title: 'Geography', class: 'Class 3', score: 43 }
      ] 
    },
    { 
      id: 16, 
      name: "Faiza Yasin", 
      result: 66, 
      subjects: [
        { title: 'History', class: 'Class 3', score: 70 },
        { title: 'Mathematics', class: 'Class 2', score: 18 },
        { title: 'Civics', class: 'Class 3', score: 62 }
      ] 
    }
  ];
  