import React from 'react';

const AttendanceCard = () => {
  return (
    <div className="bg-gray-100 shadow-md rounded-lg overflow-hidden w-full">
      <div className="max-h-[calc(100vh-550px)] overflow-y-auto w-full">
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="font-bold text-teal-600 p-2 border-b border-gray-300 bg-gray-200 sticky top-0 z-10">
                <strong>Class</strong>
              </th>
              <th className="font-bold text-teal-600 p-2 border-b border-gray-300 bg-gray-200 sticky top-0 z-10">
                <strong>Present</strong>
              </th>
              <th className="font-bold text-teal-600 p-2 border-b border-gray-300 bg-gray-200 sticky top-0 z-10">
                <strong>Absent</strong>
              </th>
              <th className="font-bold text-teal-600 p-2 border-b border-gray-300 bg-gray-200 sticky top-0 z-10">
                <strong>Late</strong>
              </th>
              <th className="font-bold text-teal-600 p-2 border-b border-gray-300 bg-gray-200 sticky top-0 z-10">
                <strong>Leave</strong>
              </th>
            </tr>
            <tr className='bg-gray-100'>
              <td className="font-bold text-teal-600 p-2 bg-gray-100 border-b border-gray-300 sticky top-9 z-10">Total</td>
              <td className="p-2 border-b border-gray-300 bg-gray-100 sticky top-12 z-10">500</td>
              <td className="p-2 border-b border-gray-300 bg-gray-100 sticky top-12 z-10">20</td>
              <td className="p-2 border-b border-gray-300 bg-gray-100 sticky top-12 z-10">5</td>
              <td className="p-2 border-b border-gray-300 bg-gray-100 sticky top-12 z-10">15</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">PG</td>
              <td className="text-center p-3 border-b border-gray-300">100</td>
              <td className="text-center p-3 border-b border-gray-300">20</td>
              <td className="text-center p-3 border-b border-gray-300">5</td>
              <td className="text-center p-3 border-b border-gray-300">15</td>
            </tr>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">Nursery</td>
              <td className="text-center p-3 border-b border-gray-300">120</td>
              <td className="text-center p-3 border-b border-gray-300">15</td>
              <td className="text-center p-3 border-b border-gray-300">10</td>
              <td className="text-center p-3 border-b border-gray-300">10</td>
            </tr>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">Prep</td>
              <td className="text-center p-3 border-b border-gray-300">90</td>
              <td className="text-center p-3 border-b border-gray-300">30</td>
              <td className="text-center p-3 border-b border-gray-300">5</td>
              <td className="text-center p-3 border-b border-gray-300">5</td>
            </tr>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">One</td>
              <td className="text-center p-3 border-b border-gray-300">100</td>
              <td className="text-center p-3 border-b border-gray-300">20</td>
              <td className="text-center p-3 border-b border-gray-300">5</td>
              <td className="text-center p-3 border-b border-gray-300">15</td>
            </tr>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">Two</td>
              <td className="text-center p-3 border-b border-gray-300">120</td>
              <td className="text-center p-3 border-b border-gray-300">15</td>
              <td className="text-center p-3 border-b border-gray-300">10</td>
              <td className="text-center p-3 border-b border-gray-300">10</td>
            </tr>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">Three</td>
              <td className="text-center p-3 border-b border-gray-300">90</td>
              <td className="text-center p-3 border-b border-gray-300">30</td>
              <td className="text-center p-3 border-b border-gray-300">5</td>
              <td className="text-center p-3 border-b border-gray-300">5</td>
            </tr>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">Four</td>
              <td className="text-center p-3 border-b border-gray-300">110</td>
              <td className="text-center p-3 border-b border-gray-300">10</td>
              <td className="text-center p-3 border-b border-gray-300">15</td>
              <td className="text-center p-3 border-b border-gray-300">15</td>
            </tr>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">Five</td>
              <td className="text-center p-3 border-b border-gray-300">100</td>
              <td className="text-center p-3 border-b border-gray-300">20</td>
              <td className="text-center p-3 border-b border-gray-300">5</td>
              <td className="text-center p-3 border-b border-gray-300">15</td>
            </tr>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">Six</td>
              <td className="text-center p-3 border-b border-gray-300">120</td>
              <td className="text-center p-3 border-b border-gray-300">15</td>
              <td className="text-center p-3 border-b border-gray-300">10</td>
              <td className="text-center p-3 border-b border-gray-300">10</td>
            </tr>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">Seven</td>
              <td className="text-center p-3 border-b border-gray-300">90</td>
              <td className="text-center p-3 border-b border-gray-300">30</td>
              <td className="text-center p-3 border-b border-gray-300">5</td>
              <td className="text-center p-3 border-b border-gray-300">5</td>
            </tr>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">Eight(Boys)</td>
              <td className="text-center p-3 border-b border-gray-300">100</td>
              <td className="text-center p-3 border-b border-gray-300">20</td>
              <td className="text-center p-3 border-b border-gray-300">5</td>
              <td className="text-center p-3 border-b border-gray-300">15</td>
            </tr>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">Eight(Girls)</td>
              <td className="text-center p-3 border-b border-gray-300">120</td>
              <td className="text-center p-3 border-b border-gray-300">15</td>
              <td className="text-center p-3 border-b border-gray-300">10</td>
              <td className="text-center p-3 border-b border-gray-300">10</td>
            </tr>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">Nine(Boys)</td>
              <td className="text-center p-3 border-b border-gray-300">100</td>
              <td className="text-center p-3 border-b border-gray-300">20</td>
              <td className="text-center p-3 border-b border-gray-300">5</td>
              <td className="text-center p-3 border-b border-gray-300">15</td>
            </tr>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">Nine(Girls)</td>
              <td className="text-center p-3 border-b border-gray-300">120</td>
              <td className="text-center p-3 border-b border-gray-300">15</td>
              <td className="text-center p-3 border-b border-gray-300">10</td>
              <td className="text-center p-3 border-b border-gray-300">10</td>
            </tr>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">Ten(Boys)</td>
              <td className="text-center p-3 border-b border-gray-300">100</td>
              <td className="text-center p-3 border-b border-gray-300">20</td>
              <td className="text-center p-3 border-b border-gray-300">5</td>
              <td className="text-center p-3 border-b border-gray-300">15</td>
            </tr>
            <tr>
              <td className="text-center p-3 border-b border-gray-300">Ten(Girls)</td>
              <td className="text-center p-3 border-b border-gray-300">120</td>
              <td className="text-center p-3 border-b border-gray-300">15</td>
              <td className="text-center p-3 border-b border-gray-300">10</td>
              <td className="text-center p-3 border-b border-gray-300">10</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AttendanceCard;
