import React from 'react';

const EventsCard = () => {
  const eventsList = [
    {
      title: 'Independence Day',
      date: '7/11/2024',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed consectetur varius metus, ut ullamcorper risus viverra in.',
    },
    {
      title: 'Labor Day',
      date: '9/2/2024',
      description: 'Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
    },
    {
      title: 'Thanksgiving',
      date: '11/28/2024',
      description: 'Nullam quis risus eget urna mollis ornare vel eu leo.',
    },
    {
      title: 'Christmas Day',
      date: '12/25/2024',
      description: 'Donec ullamcorper nulla non metus auctor fringilla.',
    },
  ];

  return (
    <div className="w-full">
      <div className="bg-gray-100 shadow-md rounded-lg max-h-[calc(100vh-550px)] overflow-y-auto">
        <h5 className="flex justify-center items-center font-bold text-teal-600 bg-gray-200 p-2">
          Events
        </h5>
        {eventsList.map((event, index) => (
          <div key={index} className="mb-4 px-2">
            <div className="flex justify-between items-center mb-2">
              <h6 className="font-bold">{event.title}</h6>
              <h6 className="font-bold ml-4">{event.date}</h6>
            </div>
            <p className="text-justify">{event.description}</p>
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventsCard;
