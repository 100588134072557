import React from 'react';

const MonthFinanceCard = () => {
  const cardStyle = {
    backgroundColor: '#f8f9fa',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  };

  const itemStyle = {
    textAlign: 'center',
    margin: '3px',
    transition: 'transform 0.3s ease', // Transition added for smooth effect
  };

  const labelStyle = {
    fontSize: '1.0em',
    fontWeight: 'bold',
    margin: '0',
    color: '#343a40',
  };

  const circleStyle = {
    width: '50px',
    height: '50px',
    background: 'linear-gradient(45deg, #FF7EE2, #DC0083)',
    color: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.0em',
    margin: '5px auto',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    lineHeight: '50px',  // Ensures text is centered vertically
    transition: 'transform 0.3s ease', // Transition added for smooth effect
  };

  const circleTextStyle = {
    margin: '0',
  };

  const handleMouseEnter = (event) => {
    event.currentTarget.style.transform = 'scale(1.5)'; // Zoom effect on hover
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.style.transform = 'scale(1)'; // Return to normal size on hover out
  };

  return (
    <div style={cardStyle}>
      <div style={itemStyle}>
        <div style={labelStyle}>
          <p>Total</p>
        </div>
        <div style={circleStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <p style={circleTextStyle}>38899</p>
        </div>
      </div>
      <div style={itemStyle}>
        <div style={labelStyle}>
          <p>Received</p>
        </div>
        <div style={circleStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <p style={circleTextStyle}>38899</p>
        </div>
      </div>
      <div style={itemStyle}>
        <div style={labelStyle}>
          <p>Adjustment</p>
        </div>
        <div style={circleStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <p style={circleTextStyle}>38899</p>
        </div>
      </div>
      <div style={itemStyle}>
        <div style={labelStyle}>
          <p>Remaining</p>
        </div>
        <div style={circleStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <p style={circleTextStyle}>38899</p>
        </div>
      </div>
    </div>
  );
}

export default MonthFinanceCard;
