export const result = [
    {
        id: '1',
        class: 'Play-Group',
        exams: [
            { title: 'Mid Term One', marks: 68, percentage: 68 },
            { title: 'Term One', marks: 70, percentage: 70 },
            { title: 'Mid Term Two', marks: 57, percentage: 57 },
            { title: 'Term Two', marks: 85, percentage: 85 },
            { title: 'Mid Term Three', marks: 55, percentage: 55 },
            { title: 'Final Term', marks: 90, percentage: 90 }
        ],
        totalPercentage: ((68 + 70 + 57 + 85 + 55 + 90) / 6).toFixed(2)
    },
    {
        id: '2',
        class: 'Nursery',
        exams: [
            { title: 'Mid Term One', marks: 75, percentage: 30 },
            { title: 'Term One', marks: 80, percentage: 80 },
            { title: 'Mid Term Two', marks: 65, percentage: 65 },
            { title: 'Term Two', marks: 78, percentage: 78 },
            { title: 'Mid Term Three', marks: 60, percentage: 60 },
            { title: 'Final Term', marks: 88, percentage: 88 }
        ],
        totalPercentage: ((30 + 80 + 65 + 78 + 60 + 88) / 6).toFixed(2)
    },
    {
        id: '3',
        class: 'Prep',
        exams: [
            { title: 'Mid Term One', marks: 82, percentage: 82 },
            { title: 'Term One', marks: 85, percentage: 85 },
            { title: 'Mid Term Two', marks: 72, percentage: 72 },
            { title: 'Term Two', marks: 90, percentage: 90 },
            { title: 'Mid Term Three', marks: 70, percentage: 70 },
            { title: 'Final Term', marks: 95, percentage: 95 }
        ],
        totalPercentage: ((82 + 85 + 72 + 90 + 70 + 95) / 6).toFixed(2)
    },
    {
        id: '4',
        class: 'Class 1',
        exams: [
            { title: 'Mid Term One', marks: 60, percentage: 60 },
            { title: 'Term One', marks: 62, percentage: 62 },
            { title: 'Mid Term Two', marks: 58, percentage: 58 },
            { title: 'Term Two', marks: 80, percentage: 80 },
            { title: 'Mid Term Three', marks: 55, percentage: 55 },
            { title: 'Final Term', marks: 85, percentage: 85 }
        ],
        totalPercentage: ((60 + 62 + 58 + 80 + 55 + 85) / 6).toFixed(2)
    },
    {
        id: '5',
        class: 'Class 2',
        exams: [
            { title: 'Mid Term One', marks: 78, percentage: 78 },
            { title: 'Term One', marks: 80, percentage: 80 },
            { title: 'Mid Term Two', marks: 70, percentage: 70 },
            { title: 'Term Two', marks: 82, percentage: 82 },
            { title: 'Mid Term Three', marks: 65, percentage: 45 },
            { title: 'Final Term', marks: 90, percentage: 90 }
        ],
        totalPercentage: ((78 + 80 + 70 + 82 + 45 + 90) / 6).toFixed(2)
    },
    {
        id: '6',
        class: 'Class 3',
        exams: [
            { title: 'Mid Term One', marks: 88, percentage: 48 },
            { title: 'Term One', marks: 85, percentage: 85 },
            { title: 'Mid Term Two', marks: 75, percentage: 75 },
            { title: 'Term Two', marks: 92, percentage: 92 },
            { title: 'Mid Term Three', marks: 78, percentage: 78 },
            { title: 'Final Term', marks: 97, percentage: 97 }
        ],
        totalPercentage: ((48 + 85 + 75 + 92 + 78 + 97) / 6).toFixed(2)
    },
    {
        id: '7',
        class: 'Class 4',
        exams: [
            { title: 'Mid Term One', marks: 72, percentage: 72 },
            { title: 'Term One', marks: 78, percentage: 78 },
            { title: 'Mid Term Two', marks: 65, percentage: 65 },
            { title: 'Term Two', marks: 82, percentage: 82 },
            { title: 'Mid Term Three', marks: 68, percentage: 68 },
            { title: 'Final Term', marks: 89, percentage: 89 }
        ],
        totalPercentage: ((72 + 78 + 65 + 82 + 68 + 89) / 6).toFixed(2)
    },
    {
        id: '8',
        class: 'Class 5',
        exams: [
            { title: 'Mid Term One', marks: 80, percentage: 80 },
            { title: 'Term One', marks: 83, percentage: 83 },
            { title: 'Mid Term Two', marks: 70, percentage: 70 },
            { title: 'Term Two', marks: 88, percentage: 88 },
            { title: 'Mid Term Three', marks: 75, percentage: 75 },
            { title: 'Final Term', marks: 93, percentage: 93 }
        ],
        totalPercentage: ((80 + 83 + 70 + 88 + 75 + 93) / 6).toFixed(2)
    },
    {
        id: '9',
        class: 'Class 6',
        exams: [
            { title: 'Mid Term One', marks: 68, percentage: 68 },
            { title: 'Term One', marks: 70, percentage: 70 },
            { title: 'Mid Term Two', marks: 63, percentage: 33 },
            { title: 'Term Two', marks: 78, percentage: 78 },
            { title: 'Mid Term Three', marks: 60, percentage: 30 },
            { title: 'Final Term', marks: 85, percentage: 85 }
        ],
        totalPercentage: ((68 + 70 + 33 + 78 + 30 + 85) / 6).toFixed(2)
    },
    {
        id: '10',
        class: 'Class 7',
        exams: [
            { title: 'Mid Term One', marks: 85, percentage: 85 },
            { title: 'Term One', marks: 88, percentage: 88 },
            { title: 'Mid Term Two', marks: 80, percentage: 80 },
            { title: 'Term Two', marks: 92, percentage: 92 },
            { title: 'Mid Term Three', marks: 82, percentage: 82 },
            { title: 'Final Term', marks: 98, percentage: 98 }
        ],
        totalPercentage: ((85 + 88 + 80 + 92 + 82 + 98) / 6).toFixed(2)
    },
    {
        id: '11',
        class: 'Eight Boys',
        exams: [
            { title: 'Mid Term One', marks: 90, percentage: 90 },
            { title: 'Term One', marks: 88, percentage: 88 },
            { title: 'Mid Term Two', marks: 85, percentage: 85 },
            { title: 'Term Two', marks: 89, percentage: 89 },
            { title: 'Mid Term Three', marks: 84, percentage: 84 },
            { title: 'Final Term', marks: 95, percentage: 95 }
        ],
        totalPercentage: ((90 + 88 + 85 + 89 + 84 + 95) / 6).toFixed(2)
    },
    {
        id: '12',
        class: 'Eight Girls',
        exams: [
            { title: 'Mid Term One', marks: 92, percentage: 92 },
            { title: 'Term One', marks: 90, percentage: 30 },
            { title: 'Mid Term Two', marks: 87, percentage: 47 },
            { title: 'Term Two', marks: 91, percentage: 91 },
            { title: 'Mid Term Three', marks: 86, percentage: 86 },
            { title: 'Final Term', marks: 96, percentage: 96 }
        ],
        totalPercentage: ((92 + 30 + 47 + 91 + 86 + 96) / 6).toFixed(2)
    },
    {
        id: '13',
        class: 'Nine Boys',
        exams: [
            { title: 'Mid Term One', marks: 88, percentage: 88 },
            { title: 'Term One', marks: 85, percentage: 85 },
            { title: 'Mid Term Two', marks: 83, percentage: 83 },
            { title: 'Term Two', marks: 87, percentage: 87 },
            { title: 'Mid Term Three', marks: 81, percentage: 81 },
            { title: 'Final Term', marks: 92, percentage: 92 }
        ],
        totalPercentage: ((88 + 85 + 83 + 87 + 81 + 92) / 6).toFixed(2)
    },
    {
        id: '14',
        class: 'Nine Girls',
        exams: [
            { title: 'Mid Term One', marks: 89, percentage: 89 },
            { title: 'Term One', marks: 86, percentage: 86 },
            { title: 'Mid Term Two', marks: 84, percentage: 84 },
            { title: 'Term Two', marks: 88, percentage: 88 },
            { title: 'Mid Term Three', marks: 82, percentage: 82 },
            { title: 'Final Term', marks: 93, percentage: 93 }
        ],
        totalPercentage: ((89 + 86 + 84 + 88 + 82 + 93) / 6).toFixed(2)
    },
    {
        id: '15',
        class: 'Ten Boys',
        exams: [
            { title: 'Mid Term One', marks: 91, percentage: 91 },
            { title: 'Term One', marks: 89, percentage: 89 },
            { title: 'Mid Term Two', marks: 87, percentage: 87 },
            { title: 'Term Two', marks: 90, percentage: 90 },
            { title: 'Mid Term Three', marks: 85, percentage: 85 },
            { title: 'Final Term', marks: 94, percentage: 94 }
        ],
        totalPercentage: ((91 + 89 + 87 + 90 + 85 + 94) / 6).toFixed(2)
    },
    {
        id: '16',
        class: 'Ten Girls',
        exams: [
            { title: 'Mid Term One', marks: 93, percentage: 93 },
            { title: 'Term One', marks: 91, percentage: 91 },
            { title: 'Mid Term Two', marks: 89, percentage: 89 },
            { title: 'Term Two', marks: 92, percentage: 92 },
            { title: 'Mid Term Three', marks: 87, percentage: 87 },
            { title: 'Final Term', marks: 97, percentage: 97 }
        ],
        totalPercentage: ((93 + 91 + 89 + 92 + 87 + 97) / 6).toFixed(2)
    }
];
