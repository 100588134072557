import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Empty } from "antd";

function FeeHead() {
  const [feeHeadData, setFeeHeadData] = useState([]);
  const [newFeeHead, setNewFeeHead] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const userRoleData = JSON.parse(localStorage.getItem("employeeRoleData"));

  useEffect(() => {
    fetchFeeHeads();
  }, []);

  const fetchFeeHeads = async () => {
    try {
      const response = await axios.get(
        "https://dotnet.rapidmis.com/feeheadgetall",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setFeeHeadData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddNewRow = () => {
    const newRow = { feeHeadId: null, feeHeadName: "", isActive: true };
    setNewFeeHead(newRow);
  };

  const handleSaveNewFeeHead = async () => {
    if (newFeeHead.feeHeadName.trim() === "") {
      toast.error("Please enter FeeHead Name");
      return;
    }

    try {
      const response = await axios.post(
        "https://dotnet.rapidmis.com/feeheadcreate",
        newFeeHead,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      console.log("New feeHead added:", response.data);
      await fetchFeeHeads();
      setNewFeeHead(null);
      toast.success("FeeHead added successfully!");
    } catch (error) {
      console.error("Error adding new feeHead:", error);
    }
  };

  const handleNewFeeHeadInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewFeeHead({
      ...newFeeHead,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleInputChange = (e, id) => {
    const { name, value, type, checked } = e.target;
    setUpdatedData({
      ...updatedData,
      [id]: {
        ...updatedData[id],
        [name]: type === "checkbox" ? checked : value,
      },
    });
  };

  const handleEdit = (feeHeadItem) => {
    setEditingId(feeHeadItem.feeHeadId);
    setUpdatedData({
      [feeHeadItem.feeHeadId]: {
        feeHeadName: feeHeadItem.feeHeadName,
        isActive: feeHeadItem.isActive,
      },
    });
  };

  const handleSave = async (id) => {
    const updatedFeeHead = updatedData[id];

    try {
      const response = await axios.put(
        `https://dotnet.rapidmis.com/feeheadupdate/${id}`,
        {
          feeHeadId: id,
          feeHeadName: updatedFeeHead.feeHeadName || "",
          isActive: updatedFeeHead.isActive,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      console.log("Updated data:", response.data);
      setFeeHeadData((prevData) =>
        prevData.map((item) =>
          item.feeHeadId === id ? { ...item, ...updatedFeeHead } : item
        )
      );
      setEditingId(null);
      toast.success("FeeHead updated successfully!");
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleCancel = () => {
    setEditingId(null);
    setUpdatedData({});
  };

  const handleCancelNewFeeHead = () => {
    setNewFeeHead(null);
  };

  // const handleDelete = async (id) => {
  //   try {
  //     const response = await axios.delete(`https://dotnet.rapidmis.com/feeHeaddelete/${id}`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
  //       }
  //     });
  //     console.log('Delete response:', response.data);
  //     setFeeHeadData(prevData => prevData.filter(item => item.feeHeadId !== id));
  //     toast.success('FeeHead deleted successfully!'); // Show success notification
  //   } catch (error) {
  //     console.error('Error deleting feeHead:', error.response ? error.response.data : error.message);
  //   }
  // };

  const isActionAvailable = (moduleId, featureId, actionId) => {
    if (
      !userRoleData ||
      !userRoleData.rolePermission ||
      !userRoleData.rolePermission.moduleList
    )
      return false;

    return userRoleData.rolePermission.moduleList.some(
      (module) =>
        module.moduleId === moduleId &&
        module.featureList.some(
          (feature) =>
            feature.featureId === featureId &&
            feature.actionList.some((action) => action.actionId === actionId)
        )
    );
  };

  return (
    <div className="bg-white p-2" style={{ fontFamily: "Roboto, sans-serif" }}>
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1">
        FEEHEAD LIST
      </h4>

      {isActionAvailable(4, 401, 40102) && (
        <div className="flex justify-end mb-2 mt-2">
          <button
            className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-600"
            onClick={handleAddNewRow}
          >
            <i className="fa-solid fa-plus"></i> Add New
          </button>
        </div>
      )}
      {isActionAvailable(4, 401, 40101) && (
        <div className="overflow-y-auto shadow-md">
          <table className="min-w-full bg-white">
            <thead className="bg-themeColor sticky top-0 z-10">
              <tr>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  <span>Sr</span>
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  <span>Fee Head</span>
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  <span>Status</span>
                </th>
                {isActionAvailable(4, 401, 40103) && (
                  <th className="py-2 px-4 border-b border-gray-300 text-white">
                    <span>Action</span>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {newFeeHead && (
                <tr>
                  <td></td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <input
                      type="text"
                      className="border-b border-gray-500 w-full px-2 py-1"
                      name="feeHeadName"
                      value={newFeeHead.feeHeadName}
                      onChange={handleNewFeeHeadInputChange}
                    />
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        name="isActive"
                        checked={newFeeHead.isActive}
                        onChange={handleNewFeeHeadInputChange}
                        className="sr-only peer"
                      />
                      <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <>
                      <i
                        className="fa-regular fa-floppy-disk cursor-pointer mr-2"
                        onClick={handleSaveNewFeeHead}
                      ></i>
                      <i
                        className="fa-regular fa-circle-xmark cursor-pointer"
                        onClick={handleCancelNewFeeHead}
                      ></i>
                    </>
                  </td>
                </tr>
              )}
              {feeHeadData.length === 0 ? (
                <tr>
                  <td colSpan="5">
                    <div className="flex flex-col items-center justify-center my-10">
                      <Empty />
                    </div>
                  </td>
                </tr>
              ) : (
                feeHeadData.map((feeHeadItem, index) => (
                  <tr key={feeHeadItem.feeHeadId} className="hover:bg-gray-50">
                    <td className="py-2 px-4 border-b border-gray-300">
                      {index + 1}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {editingId === feeHeadItem.feeHeadId ? (
                        <input
                          type="text"
                          className="py-2 px-4 border-b border-gray-300"
                          name="feeHeadName"
                          value={
                            updatedData[feeHeadItem.feeHeadId]?.feeHeadName ||
                            ""
                          }
                          onChange={(e) =>
                            handleInputChange(e, feeHeadItem.feeHeadId)
                          }
                        />
                      ) : (
                        feeHeadItem.feeHeadName
                      )}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {editingId === feeHeadItem.feeHeadId ? (
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            name="isActive"
                            checked={
                              updatedData[feeHeadItem.feeHeadId]?.isActive
                            }
                            onChange={(e) =>
                              handleInputChange(e, feeHeadItem.feeHeadId)
                            }
                            className="sr-only peer"
                          />
                          <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                      ) : (
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            checked={feeHeadItem.isActive}
                            readOnly
                            className="sr-only peer"
                          />
                          <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                      )}
                    </td>
                    {isActionAvailable(4, 401, 40103) && (
                      <td className="py-2 px-4 border-b border-gray-300">
                        {editingId === feeHeadItem.feeHeadId ? (
                          <>
                            <i
                              className="fa-regular fa-floppy-disk cursor-pointer mr-2"
                              onClick={() => handleSave(feeHeadItem.feeHeadId)}
                            ></i>
                            <i
                              className="fa-regular fa-circle-xmark cursor-pointer"
                              onClick={handleCancel}
                            ></i>
                          </>
                        ) : (
                          <>
                            <i
                              className="fa-regular fa-pen-to-square cursor-pointer mr-2"
                              onClick={() => handleEdit(feeHeadItem)}
                            ></i>
                            {/* <i className="fa-regular fa-trash-can text-lg cursor-pointer" onClick={() => handleDelete(feeHeadItem.feeHeadId)}></i> */}
                          </>
                        )}
                      </td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default FeeHead;
