import React from 'react';

const BirthdayCard = () => {
  return (
    <div className="bg-gray-100 shadow-md rounded-lg p-4 flex justify-between items-center w-full h-[100px]">
      <div className="text-left">
        <p className="text-2xl font-bold m-0">151</p>
        <p className="m-0">Students</p>
      </div>
      <div className="text-left">
        <p className="text-2xl font-bold m-0">51</p>
        <p className="m-0">Teachers</p>
      </div>
      <div className="flex justify-center items-center bg-gradient-to-r from-teal-400 to-blue-400 text-white rounded-full w-[50px] h-[50px] p-2">
        <i className="fa-solid fa-cake-candles text-2xl"></i>
      </div>
    </div>
  );
};

export default BirthdayCard;
