import { Empty } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const AddEditRolePermission = () => {
  const [roleData, setRoleData] = useState({
    roleName: '',
    modulePermissions: [],
  });
  const { roleId } = useParams();
  const navigate = useNavigate();
  const [checkedState, setCheckedState] = useState({
    viewAll: false,
    addAll: false,
    editAll: false,
    deleteAll: false,
  });

  useEffect(() => {
    const fetchRoleData = async () => {
      try {
        debugger;
        const modulePermissionsResponse = await axios.get(
          'https://dotnet.rapidmis.com/systempermissionlookup',
          { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('authToken')}`, } }
        );
  
        const modulePermissions = modulePermissionsResponse.data.moduleList;
  
        if (roleId) {
          const rolePermissionsResponse = await axios.get(
            `https://dotnet.rapidmis.com/rolepermissionbyid/${roleId}`,
            { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('authToken')}`, } }
          );
  
          const rolePermissions = rolePermissionsResponse.data;
  
          // Update module permissions with the role data (filling checkboxes)
          const updatedModulePermissions = modulePermissions.map((module) => {
            const matchedRoleModule = rolePermissions.moduleList?.find(
              (roleModule) => roleModule.moduleId === module.moduleId
            );
  
            if (matchedRoleModule) {
              return {
                ...module,
                featureList: module.featureList?.map((feature) => {
                  const matchedRoleFeature = matchedRoleModule.featureList?.find(
                    (roleFeature) => roleFeature.featureId === feature.featureId
                  );
  
                  if (matchedRoleFeature) {
                    const updatedActionList = feature.actionList?.map((action) => {
                      const matchedRoleAction = matchedRoleFeature.actionList?.find(
                        (roleAction) => roleAction.actionId === action.actionId
                      );
  
                      return {
                        ...action,
                        checked: matchedRoleAction ? true : false, // Check the box if actionId matches
                      };
                    });
  
                    return { ...feature, actionList: updatedActionList };
                  }
                  return feature;
                }),
              };
            }
            return module;
          });
  
          setRoleData({
            roleName: rolePermissions.rolePermissionName,
            modulePermissions: updatedModulePermissions,
          });
        } else {
          setRoleData((prevState) => ({
            ...prevState,
            modulePermissions,
          }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error fetching role data. Please try again later.');
      }
    };
  
    fetchRoleData();
  }, [roleId]);
  

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Prepare the role permission detail list with only checked values
    const rolePermissionDetailList = [];
  
    {roleData.modulePermissions && roleData.modulePermissions.length > 0 ? (
      roleData.modulePermissions.forEach((module) => {
        module.featureList.forEach((feature) => {
          feature.actionList.forEach((action) => {
            if (action.checked) {
              rolePermissionDetailList.push({
                moduleId: module.moduleId,
                featureId: feature.featureId,
                actionId: action.actionId,
              });
            }
          });
        });
      })
    ) : (
      <tr>
        <td colSpan="5">
          <div className="flex flex-col items-center justify-center my-10">
            <Empty />
          </div>
        </td>
      </tr>
    )}
    
    const payload = {
      rolePermissionId: roleId,
      rolePermissionName: roleData.roleName,
      rolePermissionDetailList,
    };
  
    try {
      const response = roleId
        ? await axios.put(
            `https://dotnet.rapidmis.com/rolepermissionupdate/${roleId}`,
            payload,
            { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('authToken')}`, } }
          )
        : await axios.post(
            'https://dotnet.rapidmis.com/rolepermissioncreate',
            payload,
            { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('authToken')}`, } }
          );
  
      // Check if response is successful and show toast
      if (response.status === 200 || response.status === 201) {
        toast.success(roleId ? 'Role updated successfully' : 'Role created successfully');
        setTimeout(() => {
          navigate('/panel/rolePermission');
        }, 1000);
      } else {
        toast.error('Server error: Unable to save role data.');
      }
    } catch (error) {
      console.error('Error saving role:', error);
      toast.error('Error saving role. Please try again later.');
    }
  };
  
  

  const handleHeaderCheckboxChange = (actionType) => {
    const newCheckedState = !checkedState[`${actionType}All`];
    setCheckedState((prevState) => ({
      ...prevState,
      [`${actionType}All`]: newCheckedState,
    }));

    // Update all checkboxes under this action type
    const updatedModulePermissions = roleData.modulePermissions.map((module) => {
      const updatedFeatureList = module.featureList.map((feature) => {
        const updatedActionList = feature.actionList.map((action) => {
          if (action.actionName === actionType) {
            return { ...action, checked: newCheckedState };
          }
          return action;
        });
        return { ...feature, actionList: updatedActionList };
      });
      return { ...module, featureList: updatedFeatureList };
    });
    setRoleData({ ...roleData, modulePermissions: updatedModulePermissions });
  };

  const handleFeatureCheckboxChange = (moduleId, featureId, actionType) => {
    const updatedModulePermissions = roleData.modulePermissions.map((module) => {
      if (module.moduleId === moduleId) {
        const updatedFeatureList = module.featureList.map((feature) => {
          if (feature.featureId === featureId) {
            const updatedActionList = feature.actionList.map((action) => {
              if (action.actionName === actionType) {
                return { ...action, checked: !action.checked };
              }
              return action;
            });
            return { ...feature, actionList: updatedActionList };
          }
          return feature;
        });
        return { ...module, featureList: updatedFeatureList };
      }
      return module;
    });
    setRoleData({ ...roleData, modulePermissions: updatedModulePermissions });
  };
  

  const handleChange = (e) => {
    setRoleData({
      ...roleData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className='bg-white p-2' style={{ fontFamily: 'Roboto, sans-serif' }}>
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1">
        {roleId ? 'Edit Role' : 'Add New Role'}
      </h4>

      <form onSubmit={handleSubmit} className="mt-2">
        <div className='flex justify-between items-center mb-4'>
          <div className="relative flex-grow">
            <input
              type="text"
              name="roleName"
              value={roleData.roleName}
              onChange={handleChange}
              className="block w-96 h-12 px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
              placeholder=" "
            />
            <label
              htmlFor="roleName"
              className="absolute text-sm text-themeColor duration-300 transform -translate-y-4 scale-75 top-2 left-2 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-themeColor"
            >
              Role Name*
            </label>
          </div>
          <div className="flex-shrink-0">
            <button
              type="submit"
              className="px-6 py-2 bg-blue-500 text-white hover:bg-blue-600 transition duration-300"
            >
              {roleId ? 'Update Role' : 'Create Role'}
            </button>
          </div>
        </div>

        <div className="mt-4">
          <table className="min-w-full bg-white">
            <thead className="bg-themeColor1 sticky top-0 z-10">
              <tr>
                <th className="py-2 px-4 border-b border-gray-300 text-white text-start">
                  Features
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  <input
                    type="checkbox"
                    checked={checkedState.viewAll }
                    onChange={() => handleHeaderCheckboxChange('View')}
                  />
                  {' '}View
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  <input
                    type="checkbox"
                    checked={checkedState.addAll}
                    onChange={() => handleHeaderCheckboxChange('Add')}
                  />
                  {' '}Add
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  <input
                    type="checkbox"
                    checked={checkedState.editAll}
                    onChange={() => handleHeaderCheckboxChange('Edit')}
                  />
                  {' '}Edit
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  <input
                    type="checkbox"
                    checked={checkedState.deleteAll}
                    onChange={() => handleHeaderCheckboxChange('Delete')}
                  />
                  {' '}Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {roleData.modulePermissions.length > 0 ? (
                roleData.modulePermissions.map((module) => (
                  <React.Fragment key={module.moduleId}>
                    <tr className="bg-gray-100">
                      <td colSpan="5" className="py-3 px-4 text-left font-semibold text-themeColor">
                        {module.moduleName}
                      </td>
                    </tr>
                    {module.featureList.map((featureList) => (
                      <tr key={featureList.featureId} className="border-b border-gray-300">
                        <td className="py-2 px-4 text-left">
                          <i className="fa-solid fa-caret-right ms-5 me-2"></i>
                          {featureList.featureName}
                        </td>
                        {['View', 'Add', 'Edit', 'Delete'].map((actionType) => {
                          const action = featureList.actionList.find(
                            (action) => action.actionName === actionType
                          );
                          return (
                            <td key={actionType} className="py-2 px-4 border-b border-gray-300 text-center">
                              {action ? (
                                <input
                                  type="checkbox"
                                  checked={action.checked || false}
                                  onChange={() =>
                                    handleFeatureCheckboxChange(module.moduleId, featureList.featureId, actionType)
                                  }
                                />
                              ) : (
                                <span>{" "}</span> // Placeholder if action is not available
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                <td colSpan="5">
                  <div className="flex flex-col items-center justify-center my-10">
                    <Empty />
                  </div>
                </td>
              </tr>
              )}
            </tbody>
          </table>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddEditRolePermission;
