import React from "react";
import { Routes, Route } from "react-router-dom";
import Panel from "./panel/pages/Panel";
import ParentDashboard from "./parentPanel/pages/ParentDashboard";
import LoginScreen from "./auth/LoginScreen";
import "./App.css";
import Dashboard from "./panel/pages/Dashboard";
import StudentList from "./panel/pages/StudentList";
import StaffPage from "./panel/pages/StaffList";
import AttendencePage from "./panel/pages/StudentAttendencePage";
import FinancePage from "./panel/pages/FinancePage";
import AddDateSheet from "./panel/pages/AddDateSheet";
import AssignFeePage from "./panel/pages/AssignFeePage";
import FeeConcessionsPage from "./panel/pages/FeeConcessionsPage";
import StaffAttendencePage from "./panel/pages/StaffAttendencePage";
import StaffResult from "./panel/pages/StaffResult";
import AddClass from "./panel/pages/AddClass";
import AddSection from "./panel/pages/AddSection";
import AddMedium from "./panel/pages/AddMedium";
import AddStudent from "./panel/pages/AddStudent";
import AddStaff from "./panel/pages/AddStaff";
import AddResultPage from "./panel/pages/AddResultPage";
import ViewResults from "./panel/pages/ViewResults";
import RolePermissions from "./panel/pages/RolePermissions";
import AddEditRolePermission from "./panel/pages/AddEditRolePermission";
import FamilySummary from "./panel/pages/FamilySummary";
import AddDepartment from "./panel/pages/AddDepartment";
import AddDesignation from "./panel/pages/AddDesignation";
import AddCampus from "./panel/pages/AddCampus";
import ErrorBoundary from "./ErrorBoundary";
import FeeHead from "./panel/pages/AddfeeHead.js";
import FeeReceiving from "./panel/pages/FeeReceiving.js";
import FeeChallanPage from "./panel/pages/FeeChallanPage.js";

function App() {
  const footerStyle = {
    position: "fixed",
    bottom: "0",
    right: "0",
    zIndex: "5",
    backgroundColor: "#2d2a2ac0",
    color: "#ffffff",
    padding: "2px 20px",
    borderRadius: "10px 0 0 0",
    fontSize: "12px",
  };

  const footerLinkStyle = {
    textDecoration: "none",
    color: "#ffffff",
  };

  return (
    <ErrorBoundary>
      <div className="App">
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/panel/*" element={<Panel />}>
            <Route path="" element={<Dashboard />} />
            <Route path="dashboardCampus" element={<Dashboard />} />
            <Route path="viewStudentList" element={<StudentList />} />
            <Route path="familySummary" element={<FamilySummary />} />
            <Route path="addStudent" element={<AddStudent />} />
            <Route path="updateStudent/:studentId" element={<AddStudent />} />
            <Route path="viewStaff" element={<StaffPage />} />
            <Route path="addStaff" element={<AddStaff />} />
            <Route path="updateStaff/:employeeId" element={<AddStaff />} />
            <Route path="staffAttendence" element={<StaffAttendencePage />} />
            <Route path="staffResult" element={<StaffResult />} />
            <Route path="attendence" element={<AttendencePage />} />
            <Route path="feeReceiving" element={<FeeReceiving />} />
            <Route path="financePage" element={<FinancePage />} />
            <Route path="feeChallan" element={<FeeChallanPage />} />
            <Route path="assignFee" element={<AssignFeePage />} />
            <Route path="feeConcessions" element={<FeeConcessionsPage />} />
            <Route path="feeHead" element={<FeeHead />} />
            <Route path="datesheet" element={<AddDateSheet />} />
            <Route path="addResults" element={<AddResultPage />} />
            <Route path="viewResult" element={<ViewResults />} />
            <Route path="classList" element={<AddClass />} />
            <Route path="sectionList" element={<AddSection />} />
            <Route path="designationList" element={<AddDesignation />} />
            <Route path="departmentList" element={<AddDepartment />} />
            <Route path="campusList" element={<AddCampus />} />
            <Route path="mediumList" element={<AddMedium />} />
            <Route path="rolePermission" element={<RolePermissions />} />
            <Route
              path="add-edit-role-permission"
              element={<AddEditRolePermission />}
            />
            <Route
              path="add-edit-role-permission/:roleId"
              element={<AddEditRolePermission />}
            />
          </Route>
          <Route path="/parentPanel/*" element={<ParentDashboard />}>
            {/* <Route path="" element={<Dashboard />} />
            <Route path="dashboardCampus" element={<Dashboard />} /> */}
          </Route>
        </Routes>
        <div style={footerStyle}>
          <a
            href="https://rapidmis.com/"
            style={footerLinkStyle}
            target="_blank"
            rel="noopener noreferrer"
          >
            Powered By RapidMIS
          </a>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default App;
