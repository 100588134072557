import React, { useState } from 'react';
import { Avatar, Button, Card, Form, Input, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

const UserProfile = ({ onLogout }) => {
  const [showPasswordUpdate, setShowPasswordUpdate] = useState(false);

  const handleUpdatePasswordClick = () => {
    setShowPasswordUpdate(true);
  };

  const handleSavePassword = (values) => {
    // Implement save password functionality here
    console.log('New Password:', values.password);
    setShowPasswordUpdate(false);
  };

  const handleCancelUpdate = () => {
    setShowPasswordUpdate(false);
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('token');
    console.log('Logged out');
    navigate('/', { replace: true });
  };

  const cardStyle = {
    maxWidth: '300px',
    margin: '20px auto',
    textAlign: 'center',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  };

  const avatarStyle = {
    display: 'block',
    margin: '0 auto 15px',
  };

  const buttonStyle = {
    width: '100%',
    marginBottom: '15px',
    fontFamily: 'Roboto, sans-serif'
  };

  const formStyle = {
    maxWidth: '200px',
    margin: '0 auto',
  };

  return (
    <div style={cardStyle}>
      <Avatar 
        size={85} 
        src='./images/userPlaceholder.png' 
        alt="User" 
        style={avatarStyle}
      />
      <Title level={3} style={{ marginBottom: '15px', fontSize: '1rem',fontFamily: 'Roboto, sans-serif' }}>User Name</Title>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px', }}>
        <Text strong>User Id: </Text>
        <Text>UserId</Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
        <Text strong>Password: </Text>
        <Text>********</Text>
      </div>
      {!showPasswordUpdate ? (
        <Button type="primary" style={buttonStyle} onClick={handleUpdatePasswordClick}>
          Update Password
        </Button>
      ) : (
        <>
          <Form 
            onFinish={handleSavePassword} 
            style={formStyle}
          >
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your new password!' }]}
              style={{ marginBottom: '15px' }}
            >
              <Input.Password placeholder="New Password" />
            </Form.Item>
            <Button type="primary" htmlType="submit" style={buttonStyle}>
              Save
            </Button>
            <Button type="default" style={{ ...buttonStyle, marginTop: '10px' }} onClick={handleCancelUpdate}>
              Cancel
            </Button>
          </Form>
        </>
      )}
      <Button type="default" style={buttonStyle} onClick={handleLogout}>
        Logout
      </Button>
    </div>
  );
};

export default UserProfile;
