import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "../../App.css";

const FeeConcessionsPage = () => {
  const [classesWithSession, setClassesWithSession] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [sections, setSections] = useState([]);
  const [feeHeadList, setFeeHeadList] = useState([]);
  const [feeAssignmentList, setFeeAssignmentList] = useState([]);
  const [feeConcessionWithStudentList, setFeeConcessionWithStudentList] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [discountData, setDiscountData] = useState({});
  const userRoleData = JSON.parse(localStorage.getItem("employeeRoleData"));

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://dotnet.rapidmis.com/feeconcessionlookup", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { classesWithSessionList = [], feeHeadList = [] } =
          response.data || {};
        setClassesWithSession(classesWithSessionList);
        setFeeHeadList(feeHeadList);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleClassChange = (e) => {
    const selectedClass = e.target.value;
    setSelectedClassId(selectedClass);
    const classData = classesWithSession.find(
      (c) => c.classId === parseInt(selectedClass)
    );
    setSections(classData?.sectionList || []);
  };

  const handleSectionChange = (e) => {
    const selectedSection = e.target.value;
    setSelectedSectionId(selectedSection);
  };

  const handleSearch = () => {
    if (!selectedClassId) {
      toast.error("Please select a class.");
      return;
    }
    setLoading(true);

    const url = selectedSectionId
      ? `https://dotnet.rapidmis.com/feeconcessionbyclass?ClassId=${selectedClassId}&SectionId=${selectedSectionId}`
      : `https://dotnet.rapidmis.com/feeconcessionbyclass?ClassId=${selectedClassId}`;

    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { feeAssignmentList = [], feeConcessionWithStudentList = [] } =
          response.data || {};
        setFeeAssignmentList(feeAssignmentList);
        setFeeConcessionWithStudentList(feeConcessionWithStudentList);

        const initialDiscounts = {};
        feeConcessionWithStudentList.forEach((student) => {
          initialDiscounts[student.studentId] = {};
          student.feeHeadDiscountList.forEach((feeHead) => {
            initialDiscounts[student.studentId][feeHead.feeHeadId] =
              feeHead.discount;
          });
        });
        setDiscountData(initialDiscounts);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDiscountChange = (studentId, feeHeadId, discount) => {
    setDiscountData((prev) => ({
      ...prev,
      [studentId]: {
        ...(prev[studentId] || {}),
        [feeHeadId]: discount,
      },
    }));
  };

  const handleSave = () => {
    const studentWithFeeHead = feeConcessionWithStudentList.map((student) => ({
      studentId: student.studentId,
      feeHead: feeHeadList.map((feeHead) => ({
        feeHeadId: feeHead.feeHeadId,
        discount: discountData[student.studentId]?.[feeHead.feeHeadId] || 0,
      })),
    }));

    axios
      .post(
        "https://dotnet.rapidmis.com/feeconcessionupdate",
        {
          studentWithFeeHead,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        toast.success("Fee concessions updated successfully");
      })
      .catch((error) => {
        console.error("Error updating fee concessions:", error);
        toast.error("Error updating fee concessions");
      });
  };

  const isActionAvailable = (moduleId, featureId, actionId) => {
    if (
      !userRoleData ||
      !userRoleData.rolePermission ||
      !userRoleData.rolePermission.moduleList
    )
      return false;

    return userRoleData.rolePermission.moduleList.some(
      (module) =>
        module.moduleId === moduleId &&
        module.featureList.some(
          (feature) =>
            feature.featureId === featureId &&
            feature.actionList.some((action) => action.actionId === actionId)
        )
    );
  };

  return (
    <div className="space-y-2" style={{ fontFamily: "Roboto, sans-serif" }}>
      {isActionAvailable(4, 403, 40301) && (
        <div>
          <ToastContainer />

          {loading ? (
            <div className="flex items-center justify-center h-screen">
              <div id="wifi-loader" className="relative"></div>
            </div>
          ) : (
            <>
              <div className="bg-white p-2">
                <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">
                  FILTERS
                </h4>
                <div className="flex flex-col md:flex-row md:justify-between gap-2">
                  <div className="mb-4 md:mb-0 md:w-1/3">
                    <select
                      className="block w-full p-2 border border-gray-300 bg-gray-50"
                      value={selectedClassId}
                      onChange={handleClassChange}
                    >
                      <option value="">Select Class</option>
                      {classesWithSession?.map((c) => (
                        <option key={c.classId} value={c.classId}>
                          {c.classDisplayName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-4 md:mb-0 md:w-1/3">
                    <select
                      className="block w-full p-2 border border-gray-300 bg-gray-50"
                      value={selectedSectionId}
                      onChange={handleSectionChange}
                    >
                      <option value="">Select Section</option>
                      {sections?.map((section) => (
                        <option
                          key={section.sectionId}
                          value={section.sectionId}
                        >
                          {section.sectionDisplayName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="md:w-1/3 flex justify-end">
                    <button
                      className="bg-blue-500 text-white py-2 px-4 hover:bg-blue-600 transition duration-200"
                      onClick={handleSearch}
                    >
                      <i className="fa-solid fa-magnifying-glass"></i> Search
                    </button>
                  </div>
                </div>
              </div>

              {feeAssignmentList?.length > 0 &&
                feeConcessionWithStudentList?.length > 0 && (
                  <>
                    <div className="bg-white p-2">
                      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">
                        FEE HEADS
                      </h4>
                      <table className="min-w-full border-b border-gray-300 bg-white">
                        <thead className="bg-themeColor1 text-white">
                          <tr>
                            {feeHeadList?.map((feeHead) => (
                              <th key={feeHead.feeHeadId} className="px-4 py-2">
                                {feeHead.feeHeadName.toUpperCase()}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {feeAssignmentList?.map((assignment, index) => (
                            <tr key={index}>
                              {assignment?.feeHeadAmountList?.map((fee) => (
                                <td key={fee.feeHeadId} className="px-4 py-2">
                                  {fee.amount}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}

              {feeConcessionWithStudentList.length > 0 && (
                <>
                  <div className="bg-white p-2">
                    <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">
                      STUDENTS
                    </h4>
                    <table className="border-b border-gray-300 shadow">
                      <thead className="bg-themeColor1 text-white">
                        <tr>
                          <th className="px-4 py-2">Sr No</th>
                          <th className="px-4 py-2">Student Name</th>
                          <th className="px-4 py-2">Father Name</th>
                          <th className="px-4 py-2">Family Id</th>
                          <th className="px-4 py-2">Reg No</th>
                          {feeHeadList?.map((feeHead) => (
                            <th key={feeHead.feeHeadId} className="px-4 py-2">
                              {feeHead.feeHeadName}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {feeConcessionWithStudentList.map((student, index) => (
                          <tr key={student.studentId}>
                            <td className="px-4 py-2">{index + 1}</td>
                            <td className="px-4 py-2">{student.studentName}</td>
                            <td className="px-4 py-2">{student.fatherName}</td>
                            <td className="px-4 py-2">{student.familyId}</td>
                            <td className="px-4 py-2">{student.regNo}</td>
                            {feeHeadList.map((feeHead) => {
                              // Find the corresponding fee assignment for the student
                              const feeAssignment = feeAssignmentList.find(
                                (assignment) =>
                                  assignment.feeHeadAmountList.some(
                                    (fee) => fee.feeHeadId === feeHead.feeHeadId
                                  )
                              );
                              const fee = feeAssignment?.feeHeadAmountList.find(
                                (fee) => fee.feeHeadId === feeHead.feeHeadId
                              );
                              const amount = fee ? fee.amount : 0; // Default to 0 if no fee found
                              const discount =
                                discountData[student.studentId]?.[
                                  feeHead.feeHeadId
                                ] || "";

                              return (
                                <td
                                  key={feeHead.feeHeadId}
                                  className="px-4 py-2 space-x-2 text-right"
                                >
                                  <input
                                    type="text"
                                    value={discount}
                                    maxLength="6" // Limits visible characters to 6 digits
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // Allow only numerics and empty strings, and ensure the value is within 6 digits and the specified amount limit
                                      if (
                                        value === "" ||
                                        (/^[0-9]*$/.test(value) &&
                                          value.length <= 6 &&
                                          parseFloat(value) <= amount)
                                      ) {
                                        handleDiscountChange(
                                          student.studentId,
                                          feeHead.feeHeadId,
                                          value
                                        );
                                      }
                                    }}
                                    className="border border-gray-300 p-1 ps-2 rounded w-full text-right"
                                  />
                                  <span
                                    className={`font-bold text-right pr-1 ${
                                      amount - discount < 0
                                        ? "text-red-500"
                                        : "text-black"
                                    }`}
                                  >
                                    {amount - discount}
                                  </span>
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {isActionAvailable(4, 403, 40302) && (
                      <div className="flex justify-end">
                        <button
                          className="bg-blue-600 text-white mt-2 py-2 px-4 hover:bg-blue-700 transition duration-200"
                          onClick={handleSave}
                        >
                          <i className="fa-solid fa-save"></i> Save
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default FeeConcessionsPage;
