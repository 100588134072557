import React from 'react';
import MonthFinanceChart from '../components/MonthFinanceChart';
import YearFinanceChart from '../components/YearFinanceChart';
import Clock from '../components/Clock';
import FinanceCard from '../components/FinanceCard';
import AttendanceCard from '../components/AttendanceCard';
import ActiveStudents from '../components/ActiveStudents';
import ActiveStaff from '../components/ActiveStaff';
import StudentStrengthChart from '../components/STudentStrengthChart';
import BirthdayCard from '../components/BirthdayCard';
import MonthFinanceCard from '../components/MonthFinanceCard';
import EventsCard from '../components/EventsCard';

function Dashboard() {
  return (
    <div className="">
      <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
        
        {/* Left Column */}
        <div className="col-span-12 md:col-span-3">
          <div className="flex flex-col space-y-2">
            <div className="">
              <Clock />
            </div>
            <div className="">
              <ActiveStudents />
            </div>
            <div className="">
              <ActiveStaff />
            </div>
            <div className="">
              <BirthdayCard />
            </div>
          </div>
        </div>

        {/* Middle Column */}
        <div className="col-span-12 md:col-span-5">
          <div className="flex flex-col space-y-2">
            <div className="bg-white">
              <StudentStrengthChart />
            </div>
            <div className="bg-white">
              <YearFinanceChart />
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="col-span-12 md:col-span-4">
          <div className="flex flex-col space-y-2">
            <div className="">
              <FinanceCard />
            </div>
            <div className="">
              <MonthFinanceCard />
            </div>
            <div className="">
              <EventsCard />
            </div>
            <div className="">
              <AttendanceCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
