import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const FeeChallanPage = () => {
  const [selectedType, setSelectedType] = useState('Bulk');
  const [familyId, setFamilyId] = useState('');
  const [studentId, setStudentId] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedFeeHeads, setSelectedFeeHeads] = useState([]);
  const [dueDate, setDueDate] = useState('');

  // Data for dropdowns
  const [monthList, setMonthList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [feeHeadList, setFeeHeadList] = useState([]);

  // Fetch dropdown data from API on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://dotnet.rapidmis.com/feechallanlookup', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          }
        });
        const { classList, feeHeadList, monthList } = response.data;
        setClassList(classList);
        setFeeHeadList(feeHeadList);
        setMonthList(monthList);
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };
    fetchData();
  }, []);

  const handleClassSelection = (classId) => {
    setSelectedClasses((prev) =>
      prev.includes(classId) ? prev.filter((id) => id !== classId) : [...prev, classId]
    );
  };

  const handleFeeHeadSelection = (feeHeadId) => {
    setSelectedFeeHeads((prev) =>
      prev.includes(feeHeadId) ? prev.filter((id) => id !== feeHeadId) : [...prev, feeHeadId]
    );
  };

  const toggleSelectAllClasses = () => {
    if (selectedClasses.length === classList.length) {
      setSelectedClasses([]);
    } else {
      setSelectedClasses(classList.map((c) => c.classId));
    }
  };

  const toggleSelectAllFeeHeads = () => {
    if (selectedFeeHeads.length === feeHeadList.length) {
      setSelectedFeeHeads([]);
    } else {
      setSelectedFeeHeads(feeHeadList.map((f) => f.feeHeadId));
    }
  };

  const handleGenerateChallan = async () => {
    // Find the selected month name
    const selectedMonthName = monthList.find((month) => month.monthId === selectedMonth)?.monthName || '';
    // Convert selectedClasses and selectedFeeHeads to comma-separated strings
    const classIds = selectedClasses.join(',');
    const feeHeadIds = selectedFeeHeads.join(',');
    
    // Prepare data
    const data = {
      feeMonthName: selectedMonthName,
      feeDueDate: dueDate,
      classIds: classIds,
      feeHeadIds: feeHeadIds,
      studentId: selectedType === 'Student' ? studentId || null : null
    };

    try {
      const response = await axios.post('https://dotnet.rapidmis.com/feechallanupdate', data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      toast.success("Challan generated successfully!");
    } catch (error) {
      console.error("Error generating challan:", error);
      toast.error("Failed to generate challan. Please try again.");
    }
  };

  return (
    <div className='bg-white p-2 min-h-[35rem]' style={{ fontFamily: 'Roboto, sans-serif' }}>
      <ToastContainer />
      <h4 className="text-lg font-bold text-themeColor1 text-left mb-4 border-b-2 border-themeColor1">FEE CHALLAN</h4>

      <div className="mb-4 text-start items-center">
        {['Bulk', 'Family', 'Student'].map((type) => (
          <label key={type} className="mr-6">
            <input
              type="radio"
              name="type"
              value={type}
              checked={selectedType === type}
              onChange={() => setSelectedType(type)}
              className="form-radio text-blue-600"
            />
            <span className="ml-2 text-gray-700">{type}</span>
          </label>
        ))}
      </div>

      <div className='flex gap-2 '>
        <div className="flex-col md:flex-row md:justify-between gap-2 mb-4 w-1/3 space-y-2">
          <select
            className="block w-full p-2 border border-gray-300 bg-gray-50"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            <option value="">Select a month</option>
            {monthList.map((month) => (
              <option key={month.monthId} value={month.monthId}>{month.monthName}</option>
            ))}
          </select>
          <div>
          <label className='flex justify-start mb-1 font-semibold'>
            Due Date:
          </label>
          <input
            type="date"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            placeholder='Due Date'
            className="w-full p-2 bg-gray-50 border border-gray-300 focus:outline-none focus:ring focus:ring-blue-200"
          />
          </div>
          {selectedType === 'Family' && (
            <input
              type="text"
              value={familyId}
              onChange={(e) => setFamilyId(e.target.value)}
              placeholder='Family Id'
              className="w-full p-2 bg-gray-50 border border-gray-300 focus:outline-none focus:ring focus:ring-blue-200"
            />
          )}
          {selectedType === 'Student' && (
            <input
              type="text"
              value={studentId}
              onChange={(e) => setStudentId(e.target.value)}
              placeholder='Student Id'
              className="w-full p-2 border border-gray-300 bg-gray-50 focus:outline-none focus:ring focus:ring-blue-200"
            />
          )}
          <button
        onClick={handleGenerateChallan}
        className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 w-full"
      >
        Generate Challan
      </button>
        </div>
        
        <div className="flex-col md:flex-row md:justify-between gap-2 mb-4 w-1/3 space-y-2 border border-gray-300 px-2 py-1 bg-gray-50">
          <h4 className="text-start mb-4 mx-3 text-themeColor1 border-b border-themeColor1 text-lg font-bold">Fee Head</h4>
          <div className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={selectedFeeHeads.length === feeHeadList.length}
              onChange={toggleSelectAllFeeHeads}
              className="form-check-input h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm font-medium text-gray-700">Select All</label>
          </div>
          {feeHeadList.map((feeHead) => (
            <div key={feeHead.feeHeadId} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={feeHead.feeHeadId}
                checked={selectedFeeHeads.includes(feeHead.feeHeadId)}
                onChange={() => handleFeeHeadSelection(feeHead.feeHeadId)}
                className="form-check-input h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm font-medium text-gray-700" htmlFor={feeHead.feeHeadId}>
                {feeHead.feeHeadName}
              </label>
            </div>
          ))}
        </div>
        {selectedType === 'Bulk' && (
        <div className="flex-col md:flex-row md:justify-between gap-2 mb-4 w-1/3 space-y-2 border border-gray-300 px-2 py-1 bg-gray-50">
          <h4 className="text-start mb-4 mx-3 text-themeColor1 border-b border-themeColor1 text-lg font-bold">Class</h4>
          <div className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={selectedClasses.length === classList.length}
              onChange={toggleSelectAllClasses}
              className="form-check-input h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm font-medium text-gray-700">Select All</label>
          </div>
          {classList.map((classItem) => (
            <div key={classItem.classId} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={classItem.classId}
                checked={selectedClasses.includes(classItem.classId)}
                onChange={() => handleClassSelection(classItem.classId)}
                className="form-check-input h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm font-medium text-gray-700" htmlFor={classItem.classId}>
                {classItem.classDisplayName}
              </label>
            </div>
          ))}
        </div>
      )}

      </div>

     
    </div>
  );
};

export default FeeChallanPage;
