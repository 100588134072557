import React, { useState } from 'react';

function NewStudentFeeManagement() {
  const data = [
    { accountName: 'ADMISSION DATE', totalFee: null, discount: null, receivableFee: null },
    { accountName: 'ANNUAL FUND', totalFee: null, discount: null, receivableFee: null },
    { accountName: 'EXAMINATION FEE', totalFee: null, discount: null, receivableFee: null },
    { accountName: 'TUITION FEE', totalFee: null, discount: null, receivableFee: null },
    { accountName: 'GENERATOR FEE', totalFee: null, discount: null, receivableFee: null },
    { accountName: 'AC FEE', totalFee: null, discount: null, receivableFee: null },
    { accountName: 'PRACTICALS FEE', totalFee: null, discount: null, receivableFee: null },
  ];
  

  const [feeData, setFeeData] = useState(data);

  const handleDiscountChange = (index, value) => {
    const updatedFeeData = feeData.map((fee, i) =>
      i === index ? { ...fee, discount: Number(value) } : fee
    );
    setFeeData(updatedFeeData);
  };

  return (
    <div>
      <form className="space-y-6">
        
        <fieldset>
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-200">
                <th className="border-b border-gray-300 px-2 py-3 text-themeColor">Account Title</th>
                <th className="border-b border-gray-300 px-2 py-3 text-themeColor">Total Fee</th>
                <th className="border-b border-gray-300 px-2 py-3 text-themeColor">Discount</th>
                <th className="border-b border-gray-300 px-2 py-3 text-themeColor">Receivable Fee</th>
              </tr>
            </thead>
            <tbody>
              {feeData.map((fee, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="border border-gray-200 px-4 py-2">{fee.accountName}</td>
                  <td className="border border-gray-200 px-4 py-2">{fee.totalFee}</td>
                  <td className="border border-gray-200 px-4 py-2">
                    <input
                      type="number"
                      value={fee.discount}
                      onChange={(e) => handleDiscountChange(index, e.target.value)}
                      className="w-full border border-gray-300 rounded-md p-2 text-center focus:outline-none focus:ring-2 focus:ring-themeColor"
                    />
                  </td>
                  <td className="border border-gray-200 px-4 py-2">{fee.receivableFee}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </fieldset>

        <div className="flex justify-end">
          <button type="submit" className="bg-themeColor text-white py-2 px-4 transition duration-300">
            Save Profile
          </button>
        </div>
      </form>
    </div>
  );
}

export default NewStudentFeeManagement;
