import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const AssignFeePage = () => {
  const [classData, setClassData] = useState([]);
  const [feeHeads, setFeeHeads] = useState([]);
  const [apiFeeHeads, setApiFeeHeads] = useState([]);
  const userRoleData = JSON.parse(localStorage.getItem("employeeRoleData"));

  useEffect(() => {
    const fetchLookupData = async () => {
      try {
        const response = await axios.get(
          "https://dotnet.rapidmis.com/feeassignmentlookup",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );

        const feeHeadList = response.data.feeHeadList;
        const classes = response.data.classList.map((classItem) => ({
          classId: classItem.classId,
          class: classItem.classDisplayName,
          ...Object.fromEntries(
            feeHeadList.map((feeHead) => [feeHead.feeHeadName, 0])
          ),
        }));

        setFeeHeads(feeHeadList.map((feeHead) => feeHead.feeHeadName));
        setApiFeeHeads(feeHeadList);
        setClassData(classes);
      } catch (error) {
        console.error("Error fetching fee assignment lookup data:", error);
      }
    };

    fetchLookupData();
  }, []);

  useEffect(() => {
    if (apiFeeHeads.length > 0) {
      const fetchAssignmentData = async () => {
        try {
          const response = await axios.get(
            "https://dotnet.rapidmis.com/feeassignmentgetall",
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            }
          );

          const assignmentData = response.data.map((classItem) => {
            const mappedFeeHeads = Object.fromEntries(
              classItem.feeHeadAmountList.map((fh) => {
                const matchingFeeHead = apiFeeHeads.find(
                  (apiHead) => apiHead.feeHeadId === fh.feeHeadId
                );
                return [matchingFeeHead?.feeHeadName, fh.amount];
              })
            );

            return {
              classId: classItem.classId,
              ...mappedFeeHeads,
            };
          });

          setClassData((prevClassData) => {
            return prevClassData.map((classItem) => {
              const assignmentItem = assignmentData.find(
                (item) => item.classId === classItem.classId
              );
              return assignmentItem
                ? { ...classItem, ...assignmentItem }
                : classItem;
            });
          });
        } catch (error) {
          console.error("Error fetching fee assignment data:", error);
        }
      };

      fetchAssignmentData();
    }
  }, [apiFeeHeads]);

  const handleInputChange = (index, field, value) => {
    const updatedData = [...classData];
    updatedData[index][field] = value;
    setClassData(updatedData);
  };

  const handleSave = async () => {
    const classWithFeeHead = classData.map((classItem) => ({
      classId: classItem.classId,
      feeHead: apiFeeHeads.map((feeHead) => ({
        feeHeadId: feeHead.feeHeadId,
        amount: parseFloat(classItem[feeHead.feeHeadName] || 0),
      })),
    }));

    try {
      await axios.post(
        "https://dotnet.rapidmis.com/feeassignmentupdate",
        { classWithFeeHead },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      toast.success("Fee assigned successfully!");
    } catch (error) {
      console.error("Error updating fee assignment:", error);
      toast.error("Failed to update fee assignment.");
    }
  };

  const isActionAvailable = (moduleId, featureId, actionId) => {
    if (
      !userRoleData ||
      !userRoleData.rolePermission ||
      !userRoleData.rolePermission.moduleList
    )
      return false;

    return userRoleData.rolePermission.moduleList.some(
      (module) =>
        module.moduleId === moduleId &&
        module.featureList.some(
          (feature) =>
            feature.featureId === featureId &&
            feature.actionList.some((action) => action.actionId === actionId)
        )
    );
  };

  return (
    <div className="bg-white p-4" style={{ fontFamily: "Roboto, sans-serif" }}>
      <ToastContainer />
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">
        FEE ASSIGNMENT
      </h4>
      {isActionAvailable(4, 402, 40201) && (
        <div className="overflow-x-auto shadow">
          <table className="min-w-full">
            <thead className="bg-themeColor1 text-white">
              <tr>
                <th className="px-4 py-2">Class</th>
                {feeHeads.map((feeHead, index) => (
                  <th key={index} className="px-4 py-2">
                    {feeHead}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {classData.map((data, index) => (
                <tr key={index} className="bg-gray-50 hover:bg-gray-100">
                  <td className="px-4 py-2">{data.class}</td>
                  {feeHeads.map((feeHead) => (
                    <td key={feeHead} className="px-4 py-2">
                      <input
                        className="w-full border-b border-gray-400 focus:outline-none focus:border-blue-500 text-center"
                        type="number"
                        value={data[feeHead] || 0}
                        onChange={(e) =>
                          handleInputChange(index, feeHead, e.target.value)
                        }
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {isActionAvailable(4, 402, 40202) && (
        <div className="flex justify-end mb-2 mt-2 text-center">
          <button
            className="bg-blue-500 text-white px-6 py-2 hover:bg-blue-600"
            onClick={handleSave}
          >
            <i className="fa-regular fa-floppy-disk me-1"></i> Save
          </button>
        </div>
      )}
    </div>
  );
};

export default AssignFeePage;
