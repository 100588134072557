import { Empty } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Popconfirm } from "antd";
const RolePermissions = () => {
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const userRoleData = JSON.parse(localStorage.getItem("employeeRoleData"));

  useEffect(() => {
    axios
      .get("https://dotnet.rapidmis.com/rolepermissiongetall", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => console.error("Error fetching role data:", error));
  }, []);

  const handleAddNew = () => {
    navigate("/panel/add-edit-role-permission");
  };

  const handleEdit = (roleId) => {
    navigate(`/panel/add-edit-role-permission/${roleId}`);
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://dotnet.rapidmis.com/rolepermissiondelete/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      toast.success("Role deleted successfully!");

      setRoles((prevRoles) =>
        prevRoles.filter((role) => role.rolePermissionId !== id)
      );
    } catch (error) {
      console.error(
        "Error deleting role:",
        error.response ? error.response.data : error.message
      );
      toast.error("Error deleting role. Please try again later.");
    }
  };
  const isActionAvailable = (moduleId, featureId, actionId) => {
    if (
      !userRoleData ||
      !userRoleData.rolePermission ||
      !userRoleData.rolePermission.moduleList
    )
      return false;

    return userRoleData.rolePermission.moduleList.some(
      (module) =>
        module.moduleId === moduleId &&
        module.featureList.some(
          (feature) =>
            feature.featureId === featureId &&
            feature.actionList.some((action) => action.actionId === actionId)
        )
    );
  };
  return (
    <div className="bg-white p-2" style={{ fontFamily: "Roboto, sans-serif" }}>
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1">
        ROLE PERMISSION LIST
      </h4>
      {isActionAvailable(1, 104, 10402) && (
        <div className="flex justify-end mt-2">
          <button
            className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-600"
            onClick={handleAddNew}
          >
            <i className="fa-solid fa-plus"></i> Add New
          </button>
        </div>
      )}
      {isActionAvailable(1, 104, 10401) && (
        <div
          className="mt-2 overflow-y-auto shadow-md"
          style={{ maxHeight: "500px" }}
        >
          <table className="min-w-full bg-white">
            <thead className="bg-themeColor1 sticky top-0 z-10">
              <tr>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  Sr
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  Role Name
                </th>
                {isActionAvailable(1, 104, 10403) && (
                  <th className="py-2 px-4 border-b border-gray-300 text-white">
                    Actions
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {roles.length === 0 ? (
                <tr>
                  <td colSpan="3">
                    <div className="flex flex-col items-center justify-center my-10">
                      <Empty />
                    </div>
                  </td>
                </tr>
              ) : (
                roles.map((role, index) => (
                  <tr key={role.rolePermissionId} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b border-gray-300">
                      {index + 1}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {role.rolePermissionName}
                    </td>
                    {isActionAvailable(1, 104, 10403) && (
                      <td className="py-2 px-4 border-b border-gray-300">
                        <i
                          className="fa-regular fa-pen-to-square cursor-pointer"
                          onClick={() => handleEdit(role.rolePermissionId)}
                        ></i>
                        {isActionAvailable(1, 104, 10404) && (
                          <Popconfirm
                            title="Are you sure you want to delete?"
                            onConfirm={() =>
                              handleDelete(role.rolePermissionId)
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            <i className="fa-regular fa-trash-can ml-4 cursor-pointer"></i>
                          </Popconfirm>
                        )}
                      </td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default RolePermissions;
