import React, { useEffect, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";

function StaffList() {
  const [yearSessionList, setYearSessionList] = useState([]);
  const [rolePermissionList, setRolePermissionList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [filters, setFilters] = useState({
    YearSessionId: null,
    DesignationId: null,
    DepartmentId: null,
    RolePermissionId: null,
    IsActive: null,
    Search: null,
  });
  const userRoleData = JSON.parse(localStorage.getItem("employeeRoleData"));

  useEffect(() => {
    const fetchLookupData = async () => {
      try {
        const response = await axios.get(
          "https://dotnet.rapidmis.com/employeelookup",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        const data = response.data;
        setYearSessionList(data.yearSessionList || []);
        setDepartmentList(data.departmentList || []);
        setRolePermissionList(
          data.rolePermissionList.filter((role) => role.rolePermissionName) ||
            []
        );
        setDesignationList(data.designationList || []);
        setStatusList(data.statusList || []);
      } catch (error) {
        console.error("Error fetching lookup data:", error);
      }
    };

    fetchLookupData();
    fetchStaffData();
  }, []);

  const fetchStaffData = async (applyFilters = false) => {
    try {
      const response = await axios.get(
        "https://dotnet.rapidmis.com/employeegetall",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          params: {
            YearSessionId: applyFilters ? filters.YearSessionId : 1,
            DesignationId: applyFilters ? filters.DesignationId : null,
            DepartmentId: applyFilters ? filters.DepartmentId : null,
            RolePermissionId: applyFilters ? filters.RolePermissionId : null,
            IsActive: applyFilters ? filters.IsActive : null,
            Search: applyFilters ? filters.Search : null,
          },
        }
      );

      setStaffList(response.data || []);
    } catch (error) {
      console.error("Error fetching staff data:", error);
    }
  };

  const handleFilterChange = (e, filterType) => {
    setFilters({
      ...filters,
      [filterType]: e.target.value,
    });
  };

  const handleSearchChange = (e) => {
    setFilters({
      ...filters,
      Search: e.target.value,
    });
  };

  const handleFilterClick = () => {
    fetchStaffData(true);
  };

  const isActionAvailable = (moduleId, featureId, actionId) => {
    if (
      !userRoleData ||
      !userRoleData.rolePermission ||
      !userRoleData.rolePermission.moduleList
    )
      return false;

    return userRoleData.rolePermission.moduleList.some(
      (module) =>
        module.moduleId === moduleId &&
        module.featureList.some(
          (feature) =>
            feature.featureId === featureId &&
            feature.actionList.some((action) => action.actionId === actionId)
        )
    );
  };

  return (
    <div className="space-y-4" style={{ fontFamily: "Roboto, sans-serif" }}>
      {isActionAvailable(2, 203, 20301) && (
        <div className="bg-white p-2">
          <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">
            FILTERS
          </h4>
          <div className="flex flex-col md:flex-row md:space-x-2 mb-2">
            {/* Year Session Dropdown */}
            <div className="flex-grow md:w-1/4">
              <div className="relative">
                <MenuOutlined className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
                <select
                  className="block w-full border border-gray-300 py-2 pl-10 pr-3 focus:outline-none focus:ring focus:ring-themeColor bg-gray-50"
                  onChange={(e) => handleFilterChange(e, "YearSessionId")}
                >
                  <option value="">Year Sesssion</option>
                  {yearSessionList.map((session) => (
                    <option
                      key={session.yearSessionId}
                      value={session.yearSessionId}
                    >
                      {session.yearSessionName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Designation Dropdown */}
            <div className="flex-grow md:w-1/4">
              <div className="relative">
                <MenuOutlined className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
                <select
                  className="block w-full border border-gray-300 py-2 pl-10 pr-3 focus:outline-none focus:ring focus:ring-themeColor bg-gray-50"
                  onChange={(e) => handleFilterChange(e, "DesignationId")}
                >
                  <option value="">Select Designation</option>
                  {designationList.map((designation) => (
                    <option
                      key={designation.designationId}
                      value={designation.designationId}
                    >
                      {designation.designationName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Role Dropdown */}
            <div className="flex-grow md:w-1/4">
              <div className="relative">
                <MenuOutlined className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
                <select
                  className="block w-full border border-gray-300 py-2 pl-10 pr-3 focus:outline-none focus:ring focus:ring-themeColor bg-gray-50"
                  onChange={(e) => handleFilterChange(e, "RolePermissionId")}
                >
                  <option value="">Role</option>
                  {rolePermissionList.map((role) => (
                    <option
                      key={role.rolePermissionId}
                      value={role.rolePermissionId}
                    >
                      {role.rolePermissionName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Department Dropdown */}
            <div className="flex-grow md:w-1/4">
              <div className="relative">
                <MenuOutlined className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
                <select
                  className="block w-full border border-gray-300 py-2 pl-10 pr-3 focus:outline-none focus:ring focus:ring-themeColor bg-gray-50"
                  onChange={(e) => handleFilterChange(e, "departmentId")}
                >
                  <option value="">Select Department</option>
                  {departmentList.map((department) => (
                    <option
                      key={department.departmentId}
                      value={department.departmentId}
                    >
                      {department.departmentName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Status Dropdown */}
            <div className="flex-grow md:w-1/4">
              <div className="relative">
                <MenuOutlined className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
                <select
                  className="block w-full border border-gray-300 py-2 pl-10 pr-3 focus:outline-none focus:ring focus:ring-themeColor bg-gray-50"
                  onChange={(e) => handleFilterChange(e, "IsActive")}
                >
                  <option value="">Status</option>
                  {statusList.map((status) => (
                    <option key={status.statusId} value={status.statusId}>
                      {status.statusName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* Search and Buttons */}
          <div className="flex flex-col md:flex-row md:space-x-2 my-2">
            <form className="flex-grow w-full">
              <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only"
              >
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 bg-gray-50"
                  placeholder="Search by name..."
                  value={filters.Search}
                  onChange={handleSearchChange}
                />
              </div>
            </form>
            {/* Filter Button */}

            <div className="flex gap-2 w-1/5">
              <button
                className="bg-blue-500 w-full text-white py-2 hover:bg-blue-600"
                onClick={() => window.location.reload()}
              >
                <i class="fa-solid fa-arrows-rotate"></i> Clear
              </button>
              <button
                className="bg-blue-500 w-full text-white py-2 hover:bg-blue-600"
                onClick={handleFilterClick}
              >
                <i class="fa-solid fa-filter"></i> Filter
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white p-2">
        <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">
          STAFF LIST
        </h4>
        {isActionAvailable(2, 203, 20302) && (
          <div className="flex justify-end mb-2 mt-2">
            <Link to="/panel/addStaff">
              <button className="bg-blue-500 w-full text-white  px-6 py-2 hover:bg-blue-600">
                <i className="fa-solid fa-plus"></i> Add New
              </button>
            </Link>
          </div>
        )}
        {isActionAvailable(2, 203, 20301) && (
          <div className="overflow-x-auto shadow">
            <table className="min-w-full">
              <thead>
                <tr className="bg-themeColor1 text-white">
                  <th className="border-b border-gray-300 px-2 py-3">
                    Staff Name
                  </th>
                  <th className="border-b border-gray-300 px-2 py-3">
                    F/H Name
                  </th>
                  <th className="border-b border-gray-300 px-2 py-3">
                    Designation
                  </th>
                  <th className="border-b border-gray-300 px-2 py-3">
                    Department
                  </th>
                  <th className="border-b border-gray-300 px-2 py-3">Role</th>
                  <th className="border-b border-gray-300 px-2 py-3">
                    Contact
                  </th>
                  <th className="border-b border-gray-300 text-center px-2 py-3">
                    Status
                  </th>
                  {isActionAvailable(2, 203, 20303) && (
                    <th className="border-b border-gray-300 text-center px-2 py-3">
                      Actions
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {staffList.length > 0 ? (
                  staffList.map((staff) => (
                    <tr key={staff.staffId} className="hover:bg-gray-100">
                      <td className="py-2 px-4 border-b border-gray-300">
                        {staff.employeeName}
                      </td>
                      <td className="py-2 px-4 border-b border-gray-300">
                        {staff.fatherOrHusbandName}
                      </td>
                      <td className="py-2 px-4 border-b border-gray-300">
                        {staff.designationName}
                      </td>
                      <td className="py-2 px-4 border-b border-gray-300">
                        {staff.departmentName}
                      </td>
                      <td className="py-2 px-4 border-b border-gray-300">
                        {staff.rolePermissionName}
                      </td>
                      <td className="py-2 px-4 border-b border-gray-300">
                        {staff.mobile1}
                      </td>
                      <td className="py-2 px-4 border-b border-gray-300">
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={staff.isActive}
                          />
                          <div className="w-9 h-5 bg-gray-300 rounded-full peer-checked:bg-blue-600 peer-focus:ring-4 peer-focus:ring-blue-300 transition-all duration-300"></div>
                          <div className="absolute left-[2px] top-[2px] w-4 h-4 bg-white rounded-full border border-gray-300 peer-checked:translate-x-full peer-checked:border-green-500 transition-all duration-300"></div>
                        </label>
                      </td>
                      {isActionAvailable(2, 203, 20303) && (
                        <td className="py-2 px-4 border-b border-gray-300">
                          <Link
                            to={`/panel/updateStaff/${encodeURIComponent(
                              staff.employeeId
                            )}`}
                            className=" hover:underline hover:text-themeColor2"
                          >
                            <i className="fa-regular fa-pen-to-square"></i>
                          </Link>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="py-6">
                      <Empty description="No staff data found" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default StaffList;
