import React, { useEffect, useState } from 'react';
import AddStudentForm from '../components/AddStudentForm';
import NewStudentFeeManagement from './NewStudentFeeManagement';
import { useParams } from 'react-router-dom';

const AddStudent = () => {
  const { studentId } = useParams();
  const [IsEdit, setIsEdit] = useState(false);
  const [activeTab, setActiveTab] = useState('form');

  // useEffect(() => {
  //   if (studentId) {
  //     setIsEdit(true);
  //   }
  // }, [studentId]);

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="bg-white p-2" style={{ fontFamily: 'Roboto, sans-serif' }}>
      {/* Tab Navigation */}
      <ul className="flex border-b border-themeColor1 mb-2">
        <li className="mr-2">
          <button
            className={`px-4 py-2 text-sm font-semibold ${
              activeTab === 'form' ? 'border-b-4 border-themeColor1 text-themeColor' : 'text-themeColor1'
            }`}
            onClick={() => handleTabChange('form')}
          >
            <h5 className="text-start text-themeColor1 font-bold">{IsEdit ? 'Update' : 'Add'}</h5>
          </button>
        </li>
        {IsEdit ? <li className="mr-2">
          <button
            className={`px-4 py-2 text-sm font-semibold ${
              activeTab === 'list' ? 'border-b-4 border-themeColor text-themeColor' : 'text-themeColor1'
            }`}
            onClick={() => handleTabChange('list')}
          >
            <h5 className="text-start text-themeColor1 font-bold">ADD FEE</h5>
          </button>
        </li> : null}
      </ul>

      {/* Render the active tab's content */}
      <div className="bg-white">
        {activeTab === 'form' ? <AddStudentForm handleEdit={handleEdit} /> : <NewStudentFeeManagement />}
      </div>
    </div>
  );
};

export default AddStudent;
