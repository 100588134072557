import React, { useState } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Layout, theme, Popover, Avatar } from 'antd';
import { useNavigate, Outlet } from 'react-router-dom';

import Sidebar from '../components/Sidebar';
import UserProfile from '../components/UserProfile';



const { Header, Sider, Content } = Layout;

const Panel = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (page) => {
    navigate(page);
  };
  
  const handleLogout = () => {
    // Implement logout functionality here
  };

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const colorBgContainer1 = '#EEEEEE';

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={250}
        collapsedWidth={80}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          // background: '#161D6F'
        }}
      >
        <div className="logo" />
        <Sidebar onNavigation={handleNavigation} />
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: collapsed ? 80 : 250 }}>
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            background: colorBgContainer,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Button
            className="trigger"
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{ marginLeft: 0 }}
          />
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            textAlign: 'center',
            height: '100%',
          }}>
            <img src={'../images/logo1.png'} style={{ height: '2.8rem' }} alt="Logo" />
            <div style={{ position: 'relative', height:'70px', width:'20rem', marginLeft:'1rem'}} className='my-auto mt-2'>
              <h5 style={{ color: '#102C57', margin: 0, position:'absolute', top:'0', right:'0' }} className='text-xl'><strong>COMPREHENSIVE HIGH SCHOOL</strong></h5>
              <p style={{ margin: 0, position:'absolute', bottom:'0', right:'0' }}>GT. Road. Kamoke</p>
            </div>
          </div>
          <Popover
            content={<UserProfile onLogout={handleLogout} />}
            trigger="click"
            visible={popoverVisible}
            onVisibleChange={handlePopoverVisibleChange}
            placement="bottomRight"
            style={{backgroundColor:'none'}}
          >
            <Avatar
              src={'../images/userPlaceholder.png'}
              style={{ cursor: 'pointer', width: "2rem", marginRight: 20 }}
              alt="userProfile"
              onClick={() => setPopoverVisible(!popoverVisible)}
            />
          </Popover>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 10,
            minHeight: 280,
            background: colorBgContainer1,
          }}
        >
          <Outlet/>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Panel;
