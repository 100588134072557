import React from 'react';
import AnalogClock from 'analog-clock-react';

const Clock = () => {
    const options = {
        width: "200px", // Adjust the width here
        border: true,
        borderColor: "#17a2b8",
        baseColor: "#CAF4FF",
        centerColor: "#ff6347",
        centerBorderColor: "#ffffff",
        handColors: {
            second: "#ff4500",
            minute: "#304463",
            hour: "#304463"
        },
        numbersColor: "#ffffff",
        showNumbers: true,
        showMinuteMarks: true,
        showHourMarks: true
    };

    return (
        <div className="bg-gray-100 shadow-md rounded-lg p-2 flex justify-center items-center w-11/12 h-[230px] mx-auto">
            <AnalogClock {...options} />
        </div>
    );
};

export default Clock;
