import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Empty } from 'antd';

function AddMedium() {
  const [mediumData, setMediumData] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const userRoleData = JSON.parse(localStorage.getItem("employeeRoleData"));

  useEffect(() => {
    axios.get('https://dotnet.rapidmis.com/mediumgetall', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      }
    })
    .then(response => {
      setMediumData(response.data);
    })
    .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleEdit = (mediumItem) => {
    setEditingId(mediumItem.mediumId);
    setUpdatedData({
      [mediumItem.mediumId]: { displayName: mediumItem.displayName, isActive: mediumItem.isActive }
    });
  };

  const handleSave = (id) => {
    const updatedMedium = updatedData[id];
    if (!updatedMedium) {
      console.error('No data found for mediumId:', id);
      return;
    }
  
    axios.put(`https://dotnet.rapidmis.com/mediumupdate/${id}`, {
      mediumId: id,
      displayName: updatedMedium.displayName,
      isActive: updatedMedium.isActive
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      }
    })
    .then(response => {
      setEditingId(null);
      setMediumData(prevData => prevData.map(item => item.mediumId === id ? { ...item, ...updatedMedium } : item));
      toast.success('Medium updated successfully!'); // Show success notification
    })
    .catch(error => console.error('Error:', error));
  };

  const handleCancel = () => {
    setEditingId(null);
    setUpdatedData({});
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUpdatedData({
      ...updatedData,
      [editingId]: {
        ...updatedData[editingId],
        [name]: type === 'checkbox' ? checked : value
      }
    });
  };
  const isActionAvailable = (moduleId, featureId, actionId) => {
    if (
      !userRoleData ||
      !userRoleData.rolePermission ||
      !userRoleData.rolePermission.moduleList
    ) return false;
  
    return userRoleData.rolePermission.moduleList.some((module) =>
      module.moduleId === moduleId &&
      module.featureList.some((feature) =>
        feature.featureId === featureId &&
        feature.actionList.some((action) => action.actionId === actionId)
      )
    );
  };
  return (
    <div className="bg-white p-2" style={{ fontFamily: 'Roboto, sans-serif' }}>
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1">MEDIUM LIST</h4>
{isActionAvailable(1 , 103 , 10301) && (
      <div className="mt-4">
        <div className="overflow-hidden shadow-md">
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr className="w-full bg-themeColor1">
                <th className="py-2 px-4 border-b border-gray-300 text-white">Sr</th>
                  <th className="py-2 px-4 border-b border-gray-300 text-white">Medium Name</th>
                  <th className="py-2 px-4 border-b border-gray-300 text-white">Display Name</th>
                  <th className="py-2 px-4 border-b border-gray-300 text-white">Status</th>
                  {isActionAvailable(1 , 103 , 10302) && (
                  <th className="py-2 px-4 border-b border-gray-300 text-white">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
              {mediumData.length === 0 ? (
              <tr>
                <td colSpan="5">
                  <div className="flex flex-col items-center justify-center my-10">
                    <Empty />
                  </div>
                </td>
              </tr>
            ) : (
                mediumData.map((mediumItem,index) => (
                  <tr key={mediumItem.mediumId} className="hover:bg-gray-50">
                  <td className="py-2 px-4 border-b border-gray-300">{index+1}</td>
                    <td className="py-2 px-4 border-b border-gray-300">{mediumItem.mediumName}</td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {editingId === mediumItem.mediumId ? (
                        <input
                          type="text"
                          name="displayName"
                          value={updatedData[mediumItem.mediumId]?.displayName || ''}
                          onChange={handleInputChange}
                          className="border border-gray-300 rounded p-1"
                        />
                      ) : (
                        mediumItem.displayName
                      )}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {editingId === mediumItem.mediumId ? (
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            name="isActive"
                            checked={updatedData[mediumItem.mediumId]?.isActive || false}
                            onChange={handleInputChange}
                            className="sr-only peer"
                          />
                          <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                      ) : (
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            checked={mediumItem.isActive}
                            readOnly
                            className="sr-only peer"
                          />
                          <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                      )}
                    </td>
                    {isActionAvailable(1 , 103 , 10302) && (
                    <td className="py-2 px-4 border-b border-gray-300">
                      {editingId === mediumItem.mediumId ? (
                        <>
                          <i className="fa-regular fa-floppy-disk cursor-pointer mr-2" onClick={() => handleSave(mediumItem.mediumId)}></i>
                          <i className="fa-regular fa-circle-xmark cursor-pointer" onClick={handleCancel}></i>
                        </>
                      ) : (
                        <i className="fa-regular fa-pen-to-square cursor-pointer" onClick={() => handleEdit(mediumItem)}></i>
                      )}
                    </td>
                    )}
                  </tr>
                ))
            )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
)}
      <ToastContainer />
    </div>
  );
}

export default AddMedium;
